import React from "react";
import { useState, useEffect, useRef } from "react";
import * as Navigation from "../../../specific/Navigation";
import Main from "../../../specific/components/Main/Main";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image,
    ScrollView,
    Animated,
    SafeAreaView
} from 'react-native';

import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// Assets
import Images from "../../../specific/utils/Images";

// Services
import { isConnnected } from "../../services/Auth";
import { pixelPerfect } from "../../utils/PixelPerfect";
import Button from "../../designSystem/Button";

// Views
import OnboardingTeacherPageOne from "./Onboarding/OnboardingTeacherPageOne";
import OnboardingTeacherPageTwo from "./Onboarding/OnboardingTeacherPageTwo";
import OnboardingTeacherPageThree from "./Onboarding/OnboardingTeacherPageThree";
import OnboardingTeacherPageFour from "./Onboarding/OnboardingTeacherPageFour";

const Onboarding = (props: any) => {

    let propsData = props;
    if (Platform.OS === "web") {
        propsData = props.location.state;
    }

    const scrollViewAbscissa = useRef(new Animated.Value(0)).current;

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    // ComponentDidMount
    useEffect(() => {
    }, []);

    // UI
    const { isTeacher } = propsData;
    const buttonBackgroundColor = isTeacher === true ? appTheme.schoolColor : appTheme.homeColor;
    const viewBackgroundColor = isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor;

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        componentId: propsData.componentId,
        history: propsData.history,
        showNavBar: false,
        showMenu: false,
        safeArea: false,
        backgroundColor: viewBackgroundColor,
        canGoBack: true
    });

    const getOnboardingViews = () => {
        const { isTeacher } = propsData;
        let onboardingPagerData = [
            <OnboardingTeacherPageOne key="teacherPageOne" />,
            <OnboardingTeacherPageTwo key="teacherPageTwo" />,
            <OnboardingTeacherPageThree key="teacherPageThree" />,
            <OnboardingTeacherPageFour key="teacherPageFour" />
        ]
        if (isTeacher === false) {
            onboardingPagerData = [
                <View />
            ]
        }
        let onboardingViews = [];
        for (const aDataIndex in onboardingPagerData) {
            if (Object.prototype.hasOwnProperty.call(onboardingPagerData, aDataIndex)) {
                const anOnboardingData = onboardingPagerData[aDataIndex];
                onboardingViews.push(anOnboardingData);
            }
        }
        return onboardingViews;
    }

    const getIndicatorPosition = () => {
        const screenWidth = appTheme.getFullAppWidth();
        return scrollViewAbscissa.interpolate({
            inputRange: [0, screenWidth / 2, screenWidth, screenWidth * 3 / 2, 2 * screenWidth, screenWidth * 5 / 2, 3 * screenWidth],
            outputRange: [0, 0, pixelPerfect(26), pixelPerfect(26), pixelPerfect(52), pixelPerfect(52), pixelPerfect(78)],
            extrapolate: "clamp"
        });
    }

    const getIndicatorSize = () => {
        const screenWidth = appTheme.getFullAppWidth();
        return scrollViewAbscissa.interpolate({
            inputRange: [0, screenWidth / 2, screenWidth, screenWidth * 3 / 2, 2 * screenWidth, screenWidth * 5 / 2, 3 * screenWidth],
            outputRange: [pixelPerfect(16), pixelPerfect(42), pixelPerfect(16), pixelPerfect(42), pixelPerfect(16), pixelPerfect(42), pixelPerfect(16)],
            extrapolate: "clamp"
        });
    }

    return (
        <Main mainOptions={mainOptions} >
            <Image source={images.bgOnboardingTeacher} style={{ position: "absolute", top: 0, width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight() }} resizeMode="contain" />
            <SafeAreaView style={[styles.main, { width: appTheme.getFullAppWidth() }]}>
                <Animated.ScrollView
                    onScroll={Animated.event(
                        [
                            {
                                nativeEvent: {
                                    contentOffset: { x: scrollViewAbscissa }
                                },
                            }
                        ],
                        { useNativeDriver: false }
                    )}
                    pagingEnabled={true}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    style={{ width: appTheme.getFullAppWidth(), flex: 1 }}>
                    {getOnboardingViews()}
                </Animated.ScrollView>
                <View style={{ alignItems: "flex-start", flexDirection: "row", position: "absolute", bottom: 0, width: appTheme.getFullAppWidth(), height: pixelPerfect(75) }}>
                    <View style={{ flex: 1, justifyContent: "flex-start", alignItems: "center" }}>
                        <View style={styles.positionIndicatorsRow}>
                            <View style={styles.positionIndicatorsRow}>
                                <View style={[styles.positionIndicatorCircle, { borderColor: buttonBackgroundColor }]} />
                                <View style={[styles.positionIndicatorCircle, { borderColor: buttonBackgroundColor }]} />
                                <View style={[styles.positionIndicatorCircle, { borderColor: buttonBackgroundColor }]} />
                                <View style={[styles.positionIndicatorCircle, { borderColor: buttonBackgroundColor }]} />
                                <Animated.View style={[styles.positionIndicatorPoint, { width: getIndicatorSize(), position: "absolute", left: getIndicatorPosition(), backgroundColor: buttonBackgroundColor }]} />
                            </View>
                        </View>
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={styles.authenticationButton}>
                            <Button
                                title={"JE M'INSCRIS"}
                                style={{ backgroundColor: buttonBackgroundColor, width:pixelPerfect(120) }} />
                            <Button
                                link={true}
                                title={"DÉJÀ INSCRIT(E)"}
                                style={{ marginTop:pixelPerfect(5), backgroundColor: buttonBackgroundColor }} />
                        </View>
                    </View>
                </View>
            </SafeAreaView>
        </Main>
    )
}

export default Onboarding;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    positionIndicatorsRow: {
        flexDirection: "row",
        alignItems:"center",
        justifyContent: "space-between",
        width: pixelPerfect(94),
        marginStart: pixelPerfect(10),
        marginVertical: pixelPerfect(5)
    },
    authenticationButton: {
        alignItems:"center"
    },
    positionIndicatorCircle: {
        width: pixelPerfect(16),
        height: pixelPerfect(16),
        borderRadius: pixelPerfect(8),
        borderWidth: pixelPerfect(1),
        justifyContent: 'center',
        alignItems: 'center'
    },
    positionIndicatorPoint: {
        height: pixelPerfect(16),
        borderRadius: pixelPerfect(8)
    }
});