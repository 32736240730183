import i18n from "i18n-js";
import { NativeModules, Platform } from "react-native"

import fr from "./locales/fr.json";

i18n.defaultLocale = "fr";
i18n.locale = "fr";
i18n.fallbacks = true;
i18n.translations = { fr };

export function getLanguage() {
    const availableLanguages = ["fr"];
    let deviceLanguage = "fr";
    if (Platform.OS === "ios") {
        const appleLanguages = NativeModules.SettingsManager.settings.AppleLanguages;
    } else {
        const androidLanguages = NativeModules.I18nManager.localeIdentifier;
    }

    return deviceLanguage;
}

export default i18n;