import { Dimensions, Platform, StatusBar } from 'react-native';

export const templateWidth = 320;
export const templateHeight = 597;

//Dimensions d'un iPhone X
const X_WIDTH = 375;
const X_HEIGHT = 812;

//Dimensions d'un iPhone XS Max
const XSMAX_WIDTH = 414;
const XSMAX_HEIGHT = 896;

const { height: W_HEIGHT, width: W_WIDTH } = Dimensions.get('window');


export function pixelPerfect(size: number) {
    let refWidth = templateWidth;
    let refHeight = templateHeight;
    const webWidth = Dimensions.get('window').width > 768 ? 768 : Dimensions.get('window').width;
    const webHeight = Dimensions.get('window').height > 768 ? 768 : Dimensions.get('window').height;
    const windowWidth = Platform.OS === "web" ? webWidth : Dimensions.get('window').width;
    const windowHeight = Platform.OS === "web" ? webHeight : Dimensions.get('window').height;
    const refResolution = Math.sqrt(refWidth * refWidth + refHeight * refHeight);
    const currentResolution = Math.sqrt(windowWidth * windowWidth + windowHeight * windowHeight);
    const resolutionRatio = currentResolution / refResolution;
    return ((resolutionRatio * size));
}

export function pixelPerfectForLineHeight(fontSize: number) {
    return pixelPerfect(fontSize + 6);
}

export function pixelPerfectForFont(size: number) {
    const pixelPerfectSize = pixelPerfect(size);
    if (Platform.OS === "web") {
        return (pixelPerfectSize + 4);
    }
    return (pixelPerfectSize + 1);
}

export function getStatusBarHeight() {
    let isIPhoneX = false;

    if (Platform.OS === 'ios' && !Platform.isPad && !Platform.isTVOS) {
        isIPhoneX = W_WIDTH === X_WIDTH && W_HEIGHT === X_HEIGHT || W_WIDTH === XSMAX_WIDTH && W_HEIGHT === XSMAX_HEIGHT;
    }

    return Platform.select({
        ios: isIPhoneX ? 56 : 20,
        android: StatusBar.currentHeight,
        default: 0
    })
}