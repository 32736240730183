export const teacherFormFlavor = true;

//Déclaration des écrans utilisateur
export const ScreenActivitiesTags = "/activitiesTags";
export const ScreenAppMenu = "/appMenu";
export const ScreenAuthentication = "/authentication";
export const ScreenOnboarding = "/onboarding";
export const ScreenSplashscreen = teacherFormFlavor === false ? "/" : "/Splashscreen";
export const ScreenUserProfile = "/userProfile";
export const ScreenUserTypeChoice = "/userTypeChoice";
export const ScreenTeacherForm = teacherFormFlavor === false ? "/teacherForm" : "/";
export const ScreenSignIn = "/signIn";
export const ScreenSignUp = "/signUp";

//Déclaration des collections de la base
export const DBCollectionCodes = "codes";
export const DBCollectionUsers = "users";

