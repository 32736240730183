import { app } from "./Specific_Database";

const auth = app.auth();

// Get current user
export function dbCurrentUser() {
    return auth.currentUser;
}

// User SignUp
export async function dbCreateUserWithEmailAndPassword(data: { email:string, password:string }) {
    const { email, password } = data;
    try {
        const authResult = await auth.createUserWithEmailAndPassword(email, password);
        return authResult.user;
    } catch (error) {
        throw error;
    }
}

// User SignIn
export function dbSignInWithEmailAndPassword({ email, password }) {
    return new Promise(function (resolve, reject) {
        auth.signInWithEmailAndPassword(email, password).then((userCredential) => {
            const user = userCredential.user;
            resolve(userCredential);
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            reject(error);
        });
    });
}

// User Sign out
export function dbSignOut() {
    auth.signOut();
}