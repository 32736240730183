import React from "react";
import { useState, useEffect } from "react";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image
} from 'react-native';

import AppTheme from "../../../utils/Theme";
import * as Constants from "../../../utils/Constants";
import MainOptions from "../../../models/MainOptions";
import { pixelPerfect, pixelPerfectForFont } from "../../../utils/PixelPerfect";

// Assets
import Images from "../../../../specific/utils/Images";

// SVG
import i18n from "../../../utils/Localization/Localization";

interface Props {
    name: string;
}

const OnboardingTeacherPageFour = (props: any) => {

    const [isLoading, setIsLoading] = useState(true);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const teacherTestimony = [
        {
            title: i18n.t("onboarding.teacher.pageFour.sylvieDEsclaibes.title"),
            subtitle: i18n.t("onboarding.teacher.pageFour.sylvieDEsclaibes.subtitle")
        },
        {
            title: i18n.t("onboarding.teacher.pageFour.nadine.title"),
            subtitle: i18n.t("onboarding.teacher.pageFour.nadine.subtitle")
        },
        {
            title: i18n.t("onboarding.teacher.pageFour.severine.title"),
            subtitle: i18n.t("onboarding.teacher.pageFour.severine.subtitle")
        },
        {
            title: i18n.t("onboarding.teacher.pageFour.armelle.title"),
            subtitle: i18n.t("onboarding.teacher.pageFour.armelle.subtitle")
        },
        {
            title: i18n.t("onboarding.teacher.pageFour.claire.title"),
            subtitle: i18n.t("onboarding.teacher.pageFour.claire.subtitle")
        }
    ]

    // ComponentDidMount
    useEffect(() => {

    }, []);

    const getTeacherTestimonyView = () => {
        let teacherTestimonyListView = [];
        for (const aTeacherTestimonyIndex in teacherTestimony) {
            if (Object.prototype.hasOwnProperty.call(teacherTestimony, aTeacherTestimonyIndex)) {
                const aTeacherTestimony = teacherTestimony[aTeacherTestimonyIndex];
                let subtitle = <View />;
                if (aTeacherTestimony.subtitle !== undefined) {
                    subtitle = <Text style={[styles.subtitle, { width: pixelPerfect(240), paddingStart: pixelPerfect(20), fontFamily: appTheme.primaryFont, color: appTheme.schoolColor }]}>{aTeacherTestimony.subtitle}</Text>
                }
                teacherTestimonyListView.push(
                    <View style={{ width: pixelPerfect(250), justifyContent: "center", alignItems: "center", paddingHorizontal: pixelPerfect(10), paddingBottom:pixelPerfect(5) }}>
                        <Text style={[styles.title, { fontWeight:"700", paddingStart: pixelPerfect(20), fontFamily: appTheme.primaryFont, marginTop: pixelPerfect(10), color: appTheme.titleColor }]}>{aTeacherTestimony.title}</Text>
                        {subtitle}
                    </View>
                )
            }
        }
        return <View style={{ backgroundColor: appTheme.white, paddingVertical: pixelPerfect(10), marginTop: pixelPerfect(20), borderRadius: pixelPerfect(20) }}>
            {teacherTestimonyListView}
        </View>
    }

    return (
        <View style={[styles.main, { width: appTheme.getFullAppWidth(), paddingHorizontal: pixelPerfect(10), paddingTop: pixelPerfect(10) }]}>
            <Image source={images.icSchoolBag} style={{ width: pixelPerfect(60), height: pixelPerfect(50) }} resizeMode="contain" />
            <Text style={[styles.title, { fontFamily: appTheme.primaryFont, marginTop: pixelPerfect(20), color: appTheme.schoolColor }]}>{i18n.t("onboarding.teacher.pageThree.title").toLocaleUpperCase()}</Text>
            {getTeacherTestimonyView()}
        </View>
    )
}

export default OnboardingTeacherPageFour;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        textAlign: "center",
        fontSize: pixelPerfectForFont(10),
        fontWeight: "500",
        letterSpacing: 1
    },
    subtitle: {
        textAlign: "center",
        fontSize: pixelPerfectForFont(10),
        fontWeight: "500",
        letterSpacing: 1
    },
    legend: {
        textAlign: "center",
        fontSize: pixelPerfectForFont(10),
        letterSpacing: 1
    }
});