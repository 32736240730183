import React, { Ref, useEffect, useState } from "react";
import { LegacyRef } from "react";

import {
    Animated,
    Dimensions,
    KeyboardType,
    NativeSyntheticEvent,
    ReturnKeyType,
    StyleSheet,
    TextInput,
    TextInputFocusEventData,
    Platform,
    View,
    StyleProp,
    TextStyle,
    ViewStyle,
    TouchableOpacity
} from 'react-native';

import { pixelPerfect, pixelPerfectForFont } from "../utils/PixelPerfect";
import AppTheme from "../utils/Theme";

export const horizontalMargin = pixelPerfect(20);

const DefaultTextInput = (props: {
    editable: boolean,
    autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined,
    label?: string,
    style?: StyleProp<TextStyle>,
    password?: boolean,
    value?: string,
    width?: number,
    textInputWidth?: number,
    keyboardType?: KeyboardType,
    onFocus?: ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void),
    onBlur?: ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void),
    onSubmitEditing?: void,
    textInputRef?: LegacyRef<TextInput>,
    blurOnSubmit?: boolean,
    returnKeyType?: ReturnKeyType,
    multiline?: boolean,
    titleColor?: string,
    placeholderColor?: string,
    textColor?: string,
    backgroundColor?: string
}) => {

    const [isFocused, setIsFocused] = useState(false);
    const [animatedIsFocused, setAnimatedIsFocused] = useState(new Animated.Value(0));
    const [stateValue, setStateValue] = useState(props.value);
    const [hidePassword, setHidePassword] = useState(true);

    const appTheme: AppTheme = new AppTheme();

    const {
        editable = true,
        autoCapitalize = "none",
        label = "",
        style = {},
        password = false,
        width = pixelPerfect(220),
        textInputWidth = pixelPerfect(220),
        keyboardType = "default",
        onFocus,
        onBlur,
        onSubmitEditing,
        textInputRef,
        blurOnSubmit = false,
        returnKeyType = "default",
        multiline = false,
        titleColor = "#000000",
        placeholderColor = "#000000",
        textColor = "#000000",
        backgroundColor = appTheme.inputBackgroundColor
    } = props;

    const handleSubmitEditing = () => {
        if (onSubmitEditing !== undefined) {
            onSubmitEditing();
        }
    }

    const handleFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        if (onFocus !== undefined) {
            onFocus(e);
        }
        setIsFocused(true);
        Animated.timing(animatedIsFocused, {
            toValue: 1,
            duration: 200,
            useNativeDriver: false
        }).start();
    }

    const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        if (onBlur !== undefined) {
            onBlur(e);
        }
        setIsFocused(false);
        Animated.timing(animatedIsFocused, {
            toValue: 0,
            duration: 200,
            useNativeDriver: false
        }).start();
    }

    // ComponentDidMount
    useEffect(() => {
        
    }, []);

    const onChangeText = (text: string) => {
        setStateValue(text);
    }

    const getTopValue = () => {
        if (((stateValue !== undefined) && (stateValue.length > 0) && (isFocused === false)) || (Platform.OS === "web")) {
            return pixelPerfect(5);
        } else {
            return animatedIsFocused.interpolate({
                inputRange: [0, 1],
                outputRange: [pixelPerfect(25), pixelPerfect(5)]
            });
        }
    }

    const getFontSizeValue = () => {
        if (((stateValue !== undefined) && (stateValue.length > 0) && (isFocused === false)) || (Platform.OS === "web")) {
            return Platform.OS === "web" ? pixelPerfectForFont(10) : pixelPerfectForFont(8);
        } else {
            return animatedIsFocused.interpolate({
                inputRange: [0, 1],
                outputRange: [pixelPerfect(10), pixelPerfect(8)]
            });
        }
    }

    const getfontColorValue = () => {
        if (((stateValue !== undefined) && (stateValue.length > 0) && (isFocused === false)) || (Platform.OS === "web")) {
            return titleColor;
        } else {
            return animatedIsFocused.interpolate({
                inputRange: [0, 1],
                outputRange: [placeholderColor, titleColor]
            });
        }
    }

    const inputViewHeight = multiline === false ?  Platform.OS === 'ios' ? pixelPerfect(40) : pixelPerfect(50) : pixelPerfect(120);
    const inputHeight = multiline === false ? Platform.OS === 'ios' ? pixelPerfect(30) : pixelPerfect(40) : pixelPerfect(100);
    const labelStyle:StyleProp<ViewStyle> = {
        position: 'absolute',
        fontWeight: "600",
        top: getTopValue(),
        fontSize: getFontSizeValue(),
        color: getfontColorValue(),
        paddingLeft: pixelPerfect(0),
        width,
        fontFamily: appTheme.primaryFont
    };

    const textInputStyle:StyleProp<TextStyle> = {
        fontFamily: appTheme.primaryFont,
        fontWeight: "700",
        marginTop: pixelPerfect(30),
        fontSize: pixelPerfectForFont(12),
        height: inputHeight,
        paddingRight: pixelPerfect(5),
        paddingLeft: pixelPerfect(0),
        width: textInputWidth,
        color: textColor
    }

    const webInputStyle: StyleProp<TextStyle> = Platform.select({
        web: {
            outlineStyle: 'none',
        },
    });

    const passwordIconName = hidePassword === true ? "eye-off" : "eye";
    //const passwordIconView = <IconFont name={passwordIconName} size={24} color={Colors.subtitleColor} style={{ position: 'absolute', right: 0, bottom: 5 }} onPress={() => this.setState({ hidePassword: !hidePassword })} />;
    //const iconView = password === true ? passwordIconView : null;
    const iconView = <View />;
    const labelView = <Animated.Text numberOfLines={1} style={labelStyle}>{label.toLocaleUpperCase()}</Animated.Text>;
    return (
        <View style={[style, { width, height: inputViewHeight, backgroundColor, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end', borderBottomColor: isFocused === false ? placeholderColor : titleColor, borderBottomWidth: 0.5 }]}>
            {labelView}
            <TextInput
                editable={editable !== undefined ? editable : true}
                underlineColorAndroid='transparent'
                ref={textInputRef}
                style={[style, textInputStyle, webInputStyle]}
                autoCapitalize={autoCapitalize}
                autoCorrect={false}
                clearButtonMode="while-editing"
                secureTextEntry={password}
                onChangeText={(text) => onChangeText(text)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={stateValue}
                keyboardType={keyboardType}
                blurOnSubmit={blurOnSubmit}
                onSubmitEditing={() => handleSubmitEditing()}
                returnKeyType={returnKeyType}
                multiline={multiline}
                placeholderTextColor={placeholderColor}
            />
            {iconView}
        </View>
    );
}

export default DefaultTextInput;