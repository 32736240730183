import React from "react";
import { useState, useEffect } from "react";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image
} from 'react-native';

import AppTheme from "../../../utils/Theme";
import * as Constants from "../../../utils/Constants";
import MainOptions from "../../../models/MainOptions";
import { pixelPerfect, pixelPerfectForFont } from "../../../utils/PixelPerfect";

// Assets
import Images from "../../../../specific/utils/Images";

// SVG
import i18n from "../../../utils/Localization/Localization";

interface Props {
    name: string;
}

const OnboardingTeacherPageTwo = (props: any) => {

    const [isLoading, setIsLoading] = useState(true);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    // ComponentDidMount
    useEffect(() => {

    }, []);

    const mainImageHeight = appTheme.getFullAppHeight() * 2 / 3;
    const headerSize =  pixelPerfect(50);
    const paddingTop = pixelPerfect(10);
    const titleHeight =  appTheme.getFullAppHeight() - mainImageHeight - paddingTop - headerSize;

    return (
        <View style={[styles.main, { width: appTheme.getFullAppWidth(), paddingTop }]}>
            <Image source={images.icSchoolNotebook} style={{ width: headerSize, height: headerSize }} resizeMode="contain" />
            <View style={{height:titleHeight, justifyContent:"center", alignItems:"center"}}>
                <Text style={[styles.title, { fontFamily: appTheme.primaryFont, marginTop: pixelPerfect(20), color: appTheme.schoolColor }]}>{i18n.t("onboarding.teacher.pageTwo.title").toLocaleUpperCase()}</Text>
            </View>
            <View style={{ flex: 1, justifyContent: "flex-end", marginTop: pixelPerfect(20), alignItems: "center", width: appTheme.getFullAppWidth() }}>
                <Image source={images.illOnboardingTeacherPageTwo} style={{ width: appTheme.getFullAppWidth(), height: mainImageHeight }} resizeMode="contain" />
            </View>
        </View>
    )
}

export default OnboardingTeacherPageTwo;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        textAlign: "center",
        fontSize: pixelPerfectForFont(10),
        fontWeight: "500",
        letterSpacing: 1
    },
    subtitle: {
        textAlign: "center",
        fontSize: pixelPerfectForFont(10),
        fontWeight: "500",
        letterSpacing: 1
    },
    legend: {
        textAlign: "center",
        fontSize: pixelPerfectForFont(10),
        letterSpacing: 1
    }
});