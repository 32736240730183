import * as Database from "../../specific/services/Specific_Database";

export async function getUserWithId(data: {user_id:string}) {
    const { user_id } = data;
    try {
        const userData = await Database.getUserWithId({
            user_id
        });
        return userData;
    } catch (error) {
        throw error;
    }
}

export const addUserWithData = async (data: { user_id: string, user_email: string, school_name: string, school_zipcode: string, opt_in: boolean, sub_id?: string, duration_in_month?: number, addCode?: boolean }) => {
    const { user_id, user_email, school_name, school_zipcode, opt_in, sub_id, duration_in_month, addCode } = data;
    try {
        const userAdditionResult = await Database.addUserWithData({user_id, user_email, school_name, school_zipcode, opt_in, sub_id, duration_in_month, addCode});
        return userAdditionResult;
    } catch (error) {
        throw error;
    }
}

export async function addSubToUser(data: { user_id:string, sub_id:string, duration_in_month:number }) {
    const { user_id, sub_id, duration_in_month } = data;
    try {
        const updateResult = await Database.addSubToUser({
            user_id,
            sub_id,
            duration_in_month
        });
        return updateResult;
    } catch (error) {
        throw error;
    }
}

export const createTeacherCode = async (data: { user_id: string, user_email: string, school_name: string, school_zipcode: string }) => {
    const { user_id, user_email, school_name, school_zipcode } = data;
    try {
        const updateResult = await Database.createTeacherCode({
            user_id,
            user_email,
            school_name,
            school_zipcode
        });
        return updateResult;
    } catch (error) {
        throw error;
    }
}

export function getActivities() {
    return new Promise(function (resolve, reject) {
        Database.getCollection({collection:"acti", query:""}).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function saveActivities(data: {key:string, content:any}) {
    return new Promise(function (resolve, reject) {
        const { key, content } = data;
        Database.setDocument({collection:"acti", key:key, content:content}).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}