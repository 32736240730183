import React from "react";
import { useState, useEffect } from "react";
import * as Navigation from "../../../specific/Navigation";
import Main from "../../../specific/components/Main/Main";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image
} from 'react-native';

import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// Assets
import Images from "../../../specific/utils/Images";

// Services
import { isConnnected } from "../../services/Auth";

interface Props {
    name: string;
}

const AppMenu = (props: any) => {

    const [isLoading, setIsLoading] = useState(true);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    // ComponentDidMount
    useEffect(() => {
        checkUserConnexion();
    }, []);

    const checkUserConnexion = () => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }

    // UI
    const loadingView = isLoading === true ? <ActivityIndicator color={appTheme.secondaryColor} /> : <View />;

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        componentId: "",
        showNavBar: false,
        showMenu: false
    });


    return (
        <Main mainOptions={mainOptions} >
            <View style={[styles.main, {backgroundColor: appTheme.primaryColor }]}>
                <Text>Welcome LILI</Text>
                {loadingView}
            </View>
        </Main>
    )
}

export default AppMenu;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        width: 240,
        height: 240
    },
    footer: {
        position: "absolute",
        bottom: 40
    },
    footerText: {
    }
});