import React from "react";
import { useState, useEffect } from "react";
import * as Navigation from "../../../specific/Navigation";
import Main from "../../../specific/components/Main/Main";
import i18n from "../../utils/Localization/Localization";
import { pixelPerfect, pixelPerfectForFont } from "../../utils/PixelPerfect";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image,
    ScrollView,
    Modal,
    SafeAreaView,
    Alert
} from 'react-native';

import AppTheme, { Theme } from "../../utils/Theme";
import Images from "../../../specific/utils/Images";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";
import { isUserATeacher, setUserType } from "../../utils/LocalStorage";

// UI
import Button from "../../designSystem/Button";
import TextInput from "../../designSystem/TextInput";

// SVG
import SVGView from "../../utils/SvgView";
import { default as icLiliAtHomeCircle } from "../../assets/svg/illustrations/ic_lili_at_home_circle.svg";
import { default as icLiliAtSchoolCircle } from "../../assets/svg/illustrations/ic_lili_at_school_circle.svg";
import { default as bgLiliAtHomeChoice } from "../../assets/svg/illustrations/bg_lili_at_home_choice.svg";
import { default as bgLiliAtSchoolChoice } from "../../assets/svg/illustrations/bg_lili_at_school_choice.svg";
import { default as illSuperCat } from "../../assets/svg/illustrations/ill_supercat.svg";
import { default as illTeacherDocs } from "../../assets/svg/illustrations/ill_teacher_docs.svg";
import { default as txtSuperPowers } from "../../assets/svg/illustrations/txt_super_powers.svg";
import { default as txtWellBeing } from "../../assets/svg/illustrations/txt_well_being.svg";

interface Props {
    name: string;
}

const UserTypeChoice = (props: any) => {

    const [isLoading, setIsLoading] = useState(true);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    // ComponentDidMount
    useEffect(() => {

    }, []);

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        componentId: props.componentId,
        history: props.history,
        showNavBar: false,
        showMenu: false,
        safeArea: false,
        canGoBack: false
    });

    const storeType = async (data: { isTeacher: boolean }) => {
        const { isTeacher } = data;
        const { componentId, history, onDismiss } = props;
        /*
        await setUserType({
            defineAsTeacher: isTeacher
        });
        */
        Navigation.push({
            componentId,
            history,
            name: Constants.ScreenOnboarding,
            passProps: {
                isTeacher
            }
        })
    }

    return (
        <Main mainOptions={mainOptions} showTeachForm={true}>
            <View style={[styles.main, { backgroundColor: appTheme.primaryColor }]}>
                <View style={{ width: appTheme.getFullAppWidth(), flex: 1, flexDirection: "row" }}>
                    <View style={[styles.column, { backgroundColor: appTheme.homeBackgroundColor, overflow: "hidden" }]}>
                        <View style={{ position: "absolute", top: 0, left: 0 }}>
                            <Image source={images.bgLiliAtHomeChoice} style={{ width: appTheme.getFullAppWidth() / 2, height: appTheme.getFullScreenHeight() }} />
                        </View>
                        <SafeAreaView style={styles.column}>
                            <Image source={images.icLiliAtHomeCircle} style={{ width: pixelPerfect(100), height: pixelPerfect(100) }} />
                            <SVGView Component={txtSuperPowers} width={pixelPerfect(140)} height={pixelPerfect(130)} />
                            <SVGView Component={illSuperCat} width={pixelPerfect(120)} height={pixelPerfect(120)} />
                            <Button
                                onPress={() => storeType({ isTeacher: false })}
                                title={i18n.t("authentication.userTypeChoice.liliAtHome.button")}
                                style={{ width:pixelPerfect(120), marginTop: pixelPerfect(20), backgroundColor: appTheme.homeColor }} />
                        </SafeAreaView>
                    </View>
                    <View style={[styles.column, { backgroundColor: appTheme.schoolBackgroundColor, overflow: "hidden" }]}>
                        <View style={{ position: "absolute", top: 0, left: 0 }}>
                            <Image source={images.bgLiliAtSchoolChoice} style={{ width: appTheme.getFullAppWidth() / 2, height: appTheme.getFullScreenHeight() }} />
                        </View>
                        <SafeAreaView style={styles.column}>
                            <Image source={images.icLiliAtSchoolCircle} style={{ width: pixelPerfect(100), height: pixelPerfect(100) }} />
                            <SVGView Component={txtWellBeing} width={pixelPerfect(140)} height={pixelPerfect(130)} />
                            <SVGView Component={illTeacherDocs} width={pixelPerfect(120)} height={pixelPerfect(120)} />
                            <Button
                                onPress={() => storeType({ isTeacher: true })}
                                title={i18n.t("authentication.userTypeChoice.liliAtSchool.button")}
                                style={{ width:pixelPerfect(120), marginTop: pixelPerfect(20), backgroundColor: appTheme.schoolColor }} />
                        </SafeAreaView>
                    </View>
                </View>
            </View>
        </Main>
    )
}

export default UserTypeChoice;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        width: pixelPerfect(100),
        height: pixelPerfect(100),
    },
    footer: {
        position: "absolute",
        bottom: 40
    },
    column: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        padding: pixelPerfect(20)
    },
    intro: {
        fontSize: pixelPerfectForFont(9),
        textAlign: "center",
        fontWeight: "600",
        paddingVertical: pixelPerfect(20)
    },
    illustrations: {
        width: pixelPerfect(120),
        height: pixelPerfect(120),
    }
});