import { useColorScheme } from 'react-native';

// Définition des images à être utiliser dans l'application
interface bundleImage {
    uri: string;
}

class Images {

    isDarkMode: boolean = useColorScheme() === 'dark';

    bgLiliAtHomeChoice: bundleImage | number;
    bgLiliAtSchoolChoice: bundleImage | number;
    bgOnboardingTeacher: bundleImage | number;
    bgTeacherFormLeft: bundleImage | number;
    bgTeacherFormRight: bundleImage | number;
    icBlaBlaGreenYellow: bundleImage | number;
    icBlaBlaPurpleYellow: bundleImage | number;
    icBlaBlaPurple: bundleImage | number;
    icClassMaterial: bundleImage | number;
    icFormationModules: bundleImage | number;
    icLiliAtHomeCircle: bundleImage | number;
    icLiliAtSchoolCircle: bundleImage | number;
    icLiliLesPetitsChampionsLogo: bundleImage | number;
    icLinkSheets: bundleImage | number;
    icSchoolBag: bundleImage | number;
    icSchoolNotebook: bundleImage | number;
    icSchoolMaterial: bundleImage | number;
    icTeacherFacebook: bundleImage | number;
    illOnboardingTeacherPageOne: bundleImage | number;
    illOnboardingTeacherPageTwo: bundleImage | number;
    illSuperCat: bundleImage | number;
    illTeacherCat: bundleImage | number;
    illTeacherDocs: bundleImage | number;

    constructor() {
        this.bgLiliAtHomeChoice = require("../images/bg_lili_at_home_choice.png");
        this.bgLiliAtSchoolChoice = require("../images/bg_lili_at_school_choice.png");
        this.bgOnboardingTeacher = require("../images/bg_onboarding_teacher.png");
        this.bgTeacherFormLeft = require("../images/bg_teacherForm_left.jpg");
        this.bgTeacherFormRight = require("../images/bg_teacherForm_right.jpg");
        this.icBlaBlaGreenYellow = require("../images/ic_blabla_green_yellow.png");
        this.icBlaBlaPurpleYellow = require("../images/ic_blabla_purple_yellow.png");
        this.icBlaBlaPurple = require("../images/ic_blabla_purple.png");
        this.icClassMaterial = require("../images/ic_class_material.png");
        this.icFormationModules = require("../images/ic_formation_modules.png");
        this.icLiliAtHomeCircle = require("../images/ic_lili_at_home_circle.png");
        this.icLiliAtSchoolCircle = require("../images/ic_lili_at_school_circle.png");
        this.icLiliLesPetitsChampionsLogo = require("../images/ic_lili_les_petits_champions_logo.png");
        this.icLinkSheets = require("../images/ic_link_sheets.png");
        this.icSchoolBag = require("../images/ic_school_bag.png");
        this.icSchoolNotebook = require("../images/ic_school_notebook.png");
        this.icSchoolMaterial = require("../images/ic_school_material.png");
        this.icTeacherFacebook = require("../images/ic_teacher_facebook.png");
        this.illOnboardingTeacherPageOne = require("../images/ill_onboarding_teacher_page_one.png");
        this.illOnboardingTeacherPageTwo = require("../images/ill_onboarding_teacher_page_two.png");
        this.illSuperCat = require("../images/ill_supercat.png");
        this.illTeacherCat = require("../images/ill_teacher_cat.png" );
        this.illTeacherDocs = require("../images/ill_teacher_docs.png");
        
        return this;
    }
}

export default Images;

