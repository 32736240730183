import React from "react";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import {
    ActivityIndicator,
    Image,
    Platform,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    SafeAreaView,
    Dimensions,
    Keyboard
} from 'react-native';

import AppTheme from "../../../shared/utils/Theme";
import MainOptions from "../../../shared/models/MainOptions";

import ReactCardFlip from 'react-card-flip';

const CardFlip = forwardRef((props:any, ref:any) => {

    const [ isFlipped, setIsFlipped ] = useState(false);

    const appTheme: AppTheme = new AppTheme();

    // ComponentDidMount
    useEffect(() => {
    }, []);

    useImperativeHandle(ref, () => ({

        flipTheCard() {
            console.log("Youhou");
            setIsFlipped(!isFlipped);
        }

    }));

    return (
        <ReactCardFlip isFlipped={isFlipped}>
            {props.children}
        </ReactCardFlip>
    )
});

export default CardFlip;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center"
    },
    navBar: {
        width: Dimensions.get("window").width,
        height: 60
    }
});