import React, { Component } from "react";
import { useState, useEffect, useLayoutEffect } from "react";

import {
    ActivityIndicator,
    Image,
    Platform,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    SafeAreaView,
    Dimensions
} from 'react-native';

import AppTheme, { Theme } from "../../../shared/utils/Theme";
import * as Constants from "../../../shared/utils/Constants";
import MainOptions from "../../../shared/models/MainOptions";

// UI
import Images from "../../utils/Images";

// SVG
import SVGView from "../../../shared/utils/SvgView";
import { default as bgLiliAtHomeOnboarding } from "../../../shared/assets/svg/illustrations/bg_lili_at_home_onboarding.svg";
import { default as bgLiliAtSchoolOnboarding } from "../../../shared/assets/svg/illustrations/bg_lili_at_school_onboarding.svg";
import { default as bgLili } from "../../../shared/assets/svg/illustrations/bg_lili.svg";
import { default as bgUserTypeUndefined } from "../../../shared/assets/svg/illustrations/bg_user_type_undefined.svg";

import { isUserATeacher } from "../../../shared/utils/LocalStorage";
import { pixelPerfect } from "../../../shared/utils/PixelPerfect";
import Button from "../../../shared/designSystem/Button";

import * as Navigation from "../../Navigation";

const Main = (props: any) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const mainOptions: MainOptions = props.mainOptions;
    const mainBackgroundColor = mainOptions.backgroundColor !== undefined ? mainOptions.backgroundColor : appTheme.primaryColor;

    const [isTeacher, setIsTeacher] = useState(false);
    const [userTypeDefined, setUserTypeDefined] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(appTheme.daySkyColor);

    // ComponentDidMount
    useEffect(() => {
        checkUserType();
    }, []);

    const checkUserType = async () => {
        const userType: boolean | undefined = await isUserATeacher();
        if (userType !== undefined) {
            setIsTeacher(userType);
            setUserTypeDefined(true);
            if (userType === true) {
                setBackgroundColor(appTheme.schoolColor);
            } else {
                setBackgroundColor(appTheme.homeColor);
            }
        } else {
            setUserTypeDefined(false);
            setBackgroundColor(appTheme.typeChoicColor)
        }
    }

    const menu = () => {
        let menu = <View />;
        if (appTheme.getFullAppWidth() > 460) {
            // On va afficher le menu dans la barre de navigation
            menu = <View style={{ flexDirection: "row", flex: 1, justifyContent: "flex-end", alignItems: "center" }}>
                <Text>Item 1</Text>
                <Text>Item 2</Text>
                <Text>Item 3</Text>
                <Text>Item 4</Text>
            </View>;
        } else {
            // On va afficher le burger menu
            menu = <View style={{ flexDirection: "row", flex: 1, justifyContent: "flex-end", alignItems: "center" }}>
                <Text>Burger</Text>
            </View>;
        }
        return menu;
    }

    const navBar = () => {
        if (mainOptions.showMenu === true) {
            return <View style={[styles.navBar, styles.navBarShadow, { backgroundColor: appTheme.navigationColor, width: appTheme.getFullScreenWidth(), justifyContent: "center", alignItems: "center" }]}>
                <View style={{ width: appTheme.getFullAppWidth(), paddingHorizontal: 20, flexDirection: "row" }}>
                    <Text style={{ color: appTheme.navigationItemColor }}>Kycvwy</Text>
                    {menu()}
                </View>
            </View>
        }
        return <View />;
    }

    const teacherFormButton = () => {
        console.log(props);
        if (props.showTeachForm === true) {
            return (
                <View style={[styles.main, { position:"absolute", top:0, width: appTheme.getFullAppWidth(), height: pixelPerfect(100), alignItems: "center", justifyContent: "center" }]}>
                    <Button onPress={showTeacherForm} style={{backgroundColor:appTheme.typeChoicColor}} title={"Montre moi la page pour les Petits Champions"} />
                </View>
            )
        }
        return <View />;
    }

    const showTeacherForm = () => {
        Navigation.push(
            props.mainOptions.componentId,
            props.mainOptions.history,
            {},
            Constants.ScreenTeacherForm,
            {}
        );
    }

    const getBackground = () => {
        const isTeacherForm:boolean = props.isTeacherForm ?? false;
        if (isTeacherForm === true) {
            return (<View style={{ position: "absolute", top: 0, left: 0, width: appTheme.getFullScreenWidth(), height: appTheme.getFullScreenHeight(), backgroundColor: "#FFF1E1", flexDirection: "row" }}>
                <Image source={images.bgTeacherFormLeft} style={{ width: appTheme.getFullScreenWidth() / 2, height: appTheme.getFullScreenHeight() }} resizeMode="contain" />
                <Image source={images.bgTeacherFormRight} style={{ width: appTheme.getFullScreenWidth() / 2, height: appTheme.getFullScreenHeight() }} resizeMode="contain" />
            </View>);
        }

        let backgroundComponent = bgUserTypeUndefined;
        if (userTypeDefined === true) {
            if (isTeacher === true) {
                backgroundComponent = bgLiliAtSchoolOnboarding
            } else {
                backgroundComponent = bgLiliAtHomeOnboarding
            }
        }
        return (<View style={{ position: "absolute", top: 0, left: 0, width: appTheme.getFullScreenWidth(), height: appTheme.getFullScreenHeight() }}>
            <SVGView Component={bgUserTypeUndefined} width={appTheme.getFullScreenWidth()} height={appTheme.getFullScreenHeight()} />
        </View>);

    }

    const getMainViewStyle = () => {
        const isTeacherForm:boolean = props.isTeacherForm ?? false;
        const marginTop: number = mainOptions.showMenu === true ? 60 : 0;
        const childrenCointainerWidth = appTheme.getFullAppWidth();
        let mainViewStyle: any[] = [{ justifyContent: "center", alignItems: "center", width: childrenCointainerWidth, marginTop, height: appTheme.getFullScreenHeight() }];
        if (isTeacherForm === false) {
            mainViewStyle.unshift(styles.webViewShadow);
        }
        return mainViewStyle;
    }


    return (
        <View style={[styles.main, { backgroundColor: backgroundColor, width: appTheme.getFullScreenWidth(), height: appTheme.getFullScreenHeight() }]}>
            {getBackground()}
            <View style={[getMainViewStyle(), { backgroundColor: mainBackgroundColor }]}>
                {props.children}
            </View>
            {navBar()}
        </View>
    )
}

export default Main;

const styles = StyleSheet.create({
    main: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    navBar: {
        position: "absolute",
        width: Dimensions.get("window").width,
        height: 60
    },
    navBarShadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    webViewShadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    }
});