import React from "react";
import { useState, useEffect, useRef } from "react";
import * as Navigation from "../../../specific/Navigation";
import Main from "../../../specific/components/Main/Main";
import i18n from "../../utils/Localization/Localization";
import { pixelPerfect, pixelPerfectForFont } from "../../utils/PixelPerfect";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image,
    ScrollView,
    Modal,
    SafeAreaView,
    Animated,
    LayoutChangeEvent
} from 'react-native';

import AppTheme, { Theme } from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";
import { isUserATeacher, setUserType } from "../../utils/LocalStorage";

// UI
import Button from "../../designSystem/Button";
import TextInput from "../../designSystem/TextInput";

// Assets
import Images from "../../../specific/utils/Images";

// CardFlip
import CardFlip from "../../../specific/components/CardFlip/CardFlip";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

interface Props {
    name: string;
}

const Authentication = (props: any) => {

    const [isLoading, setIsLoading] = useState(true);
    const [firstViewOpacity] = useState(new Animated.Value(1));
    const [secondViewOpacity] = useState(new Animated.Value(0));

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const [screenHeight, setScreenHeight] = useState(appTheme.getFullScreenHeight);

    // ComponentDidMount
    useEffect(() => {

    }, []);

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        componentId: "",
        showNavBar: false,
        showMenu: false,
        safeArea: true
    });

    const cardFlipRef = useRef(null);

    const onFlipRequest = (targetFirstViewOpacity: number) => {
        cardFlipRef.current?.flipTheCard();
        Animated.parallel([
            Animated.timing(firstViewOpacity, {
                toValue: targetFirstViewOpacity,
                duration: 500,
                useNativeDriver: false
            }),
            Animated.timing(secondViewOpacity, {
                toValue: 1 - targetFirstViewOpacity,
                duration: 500,
                useNativeDriver: false
            })
        ]).start();
    }

    const calibrateHeight = (layoutEvent:LayoutChangeEvent) => {
        setScreenHeight(layoutEvent.nativeEvent.layout.height);
    }

    return (
        <Main mainOptions={mainOptions}>
            <View style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: appTheme.windowHeight, top: 0, left: 0 }}>
                <View style={{ flex: 2, backgroundColor: appTheme.daySkyColor }} />
                <View style={{ flex: 1, backgroundColor: appTheme.grassColor, flexDirection: "row", overflow: "hidden" }}>
                    <Image source={images.bgHomeGrass} style={{ width: pixelPerfect(360) }} resizeMode="repeat" />
                    <Image source={images.bgHomeGrass} style={{ width: pixelPerfect(360) }} resizeMode="repeat" />
                </View>
            </View>
            <ScrollView onLayout={calibrateHeight} style={{ flex: 1 }}>
                <View style={{ width: appTheme.getFullAppWidth(), height:screenHeight }}>
                    <CardFlip ref={cardFlipRef}>
                        <Animated.View style={{ flex: 1, opacity: firstViewOpacity }}>
                            <SignUp onFlipRequest={() => onFlipRequest(0)} />
                        </Animated.View>
                        <Animated.View style={{ flex: 1, opacity: secondViewOpacity }}>
                            <SignIn onFlipRequest={() => onFlipRequest(1)} />
                        </Animated.View>
                    </CardFlip>
                </View>
            </ScrollView>
        </Main>
    )
}

export default Authentication;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});