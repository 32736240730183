import React from "react";
import { useState, useEffect } from "react";
import * as Navigation from "../../../specific/Navigation";
import Main from "../../../specific/components/Main/Main";
import i18n from "../../utils/Localization/Localization";
import { pixelPerfect, pixelPerfectForFont } from "../../utils/PixelPerfect";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image,
    TextInput,
    ScrollView,
    SafeAreaView,
    Animated
} from 'react-native';

import AppTheme, { Theme } from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// Assets
import Images from "../../../specific/utils/Images";

import SVGView from "../../utils/SvgView";
import { default as Running } from "../../assets/svg/icons/solid/running.svg";

interface Props {
    name: string;
}

const SignIn = (props: any) => {

    const [descriptionLineNumber, setDescriptionLineNumber] = useState(4);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    // ComponentDidMount
    useEffect(() => {
    }, []);

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        componentId: "",
        showNavBar: false,
        showMenu: false,
        safeArea: false
    });

    const footer = () => {
        return (<View style={styles.footer}>
            <Text style={{ ...styles.footerText, color: appTheme.secondaryColor }}>
                Jidokapp - Build 1
            </Text>
        </View>);
    }

    const showFullDescription = () => {
        if (descriptionLineNumber === 4) {
            setDescriptionLineNumber(0);
        } else {
            setDescriptionLineNumber(4);
        }
    }
    const paddingTopSecondScreen = appTheme.getFlexDirectionForSplitScreen() === "row" ? pixelPerfect(0) : pixelPerfect(10);
    const widthForThirdOfTheScreen = Dimensions.get("window").width/3 < pixelPerfect(320) ?  Dimensions.get("window").width/3 : pixelPerfect(320);
    const widthForLeftPanel = appTheme.getFlexDirectionForSplitScreen() === "row" ? widthForThirdOfTheScreen : Dimensions.get("window").width;
    return (
        <Main mainOptions={mainOptions} >
            <View style={{ width: appTheme.getFullAppWidth(), height: pixelPerfect(170) }}>
                <Image
                    source={{ uri: "https://images.unsplash.com/photo-1507525428034-b723cf961d3e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2073&q=80" }}
                    style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: pixelPerfect(140) }}
                    resizeMode="cover" />
                <View style={[styles.avatarShadow, { width: pixelPerfect(80), position: "absolute", bottom: pixelPerfect(10), left: pixelPerfect(30), height: pixelPerfect(80), borderRadius: pixelPerfect(40) }]}>
                    <Image
                        source={{ uri: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80" }}
                        style={{ width: pixelPerfect(80), height: pixelPerfect(80), borderRadius: pixelPerfect(40), borderWidth: pixelPerfect(2), borderColor: "#ffffff" }}
                        resizeMode="cover" />
                </View>
            </View>
            <SafeAreaView style={[styles.main, { backgroundColor: appTheme.primaryColor, paddingTop: pixelPerfect(10) }]}>
                <ScrollView style={{ flex: 1 }}>
                    <View style={{ paddingHorizontal: pixelPerfect(10), flexDirection: appTheme.getFlexDirectionForSplitScreen(), width: appTheme.getFullAppWidth(), flex: 1 }}>
                        <View style={{ width:widthForLeftPanel }}>
                            <View>
                                <Text style={[styles.userName, { fontFamily: appTheme.primaryFont }]}>Axel de Sainte Marie</Text>
                                <Text style={[styles.userTitle, { fontFamily: appTheme.primaryFont }]}>Fondateur - Show Runner de Projets Mobiles @OnTheBeach.dev</Text>
                                <Text style={[styles.userTitle, { fontFamily: appTheme.primaryFont }]}>Directeur technique et cofondateur @LILI</Text>
                            </View>
                            <View style={{ flexDirection: "row", justifyContent: "flex-start", paddingVertical: 10 }}>
                                <View style={{ marginStart: 10 }}>
                                    <SVGView Component={Running} width={40} height={40} color={"#FF0000"} />
                                </View>
                            </View>
                        </View>
                        <View style={{ flex: 4, paddingTop: paddingTopSecondScreen }}>
                            <TouchableOpacity onPress={showFullDescription}>
                                <Text numberOfLines={descriptionLineNumber} style={[styles.userDescription, { fontFamily: appTheme.secondaryFont }]}>{"I acquiered a strong understanding of customer needs - be they business or individual - through my experiences in the management of their projects - from pre-sales to client support, in the Functional and IT Teams - since 2005.\nAs manager of my own company during 2 years, I had the opportunity to get a closer look to companies constraints and obligations - prospect phases, financial balance, project management, and administrative obligations - associated with the satisfaction to succeed on an overall work.\nSince 2011, the technical side of the projects are the main goal of my missions, allowing me to act at the heart of those ones. I am eager to use this knowledge while working with the functional ans technical teams of a company, where needs are analyzed and projects are defined. I wish to share my skills - management, support, business and technical watch, needs analysis - with a company whose projects are in harmony with the challenges of new and emerging technologies."}</Text>
                                <Text style={[styles.underlineLink, { fontFamily: appTheme.secondaryFont }]}>Show more</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </SafeAreaView>
        </Main>
    )
}

export default SignIn;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        width: 240,
        height: 240
    },
    footer: {
        position: "absolute",
        bottom: 40
    },
    footerText: {
    },
    input: {
        height: pixelPerfect(40),
        marginVertical: pixelPerfect(5),
        borderWidth: pixelPerfect(1),
        padding: pixelPerfect(10),
        width: pixelPerfect(260),
        borderRadius: pixelPerfect(10)
    },
    button: {
        height: pixelPerfect(40),
        padding: pixelPerfect(10),
        marginVertical: pixelPerfect(10),
        justifyContent: "center",
        alignItems: "center",
        width: pixelPerfect(260),
        borderRadius: pixelPerfect(10)
    },
    buttonTitle: {
        fontSize: pixelPerfectForFont(13)
    },
    avatarShadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    userName: {
        fontWeight: "500",
        fontSize: pixelPerfectForFont(14)
    },
    userTitle: {
        fontWeight: "300",
        fontSize: pixelPerfectForFont(12)
    },
    userDescription: {
        fontWeight: "300",
        fontSize: pixelPerfectForFont(12)
    },
    underlineLink: {
        textDecorationLine: "underline",
        paddingTop: pixelPerfect(2),
        fontWeight: "400"
    }
});