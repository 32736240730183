import React from "react";
import { useState, useEffect } from "react";
import * as Navigation from "../../../specific/Navigation";
import Main from "../../../specific/components/Main/Main";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image
} from 'react-native';

import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// Assets
import Images from "../../../specific/utils/Images";

// Services
import { isConnnected, signOut } from "../../services/Auth";

// Utils
import i18n from "../../utils/Localization/Localization";
import { version, build } from "../../../../package.json";
import { isUserATeacher } from "../../utils/LocalStorage";

import { wipeData } from "../../utils/LocalStorage";

interface Props {
    name: string;
}

const Splashscreen = (props: any) => {

    const [isLoading, setIsLoading] = useState(true);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    // ComponentDidMount
    useEffect(() => {
        wipeData();
        checkUserConnexion();
    }, []);

    const checkUserConnexion = () => {
        setTimeout(() => {
            setIsLoading(false);
            const isUserConnected = isConnnected();
            if (isUserConnected === true) {
                //launchTheApp();
                signOut();
            } else {
                checkUserType();
            }
        }, 500);
    }

    const checkUserType = async () => {
        const isTeacher = await isUserATeacher();
        let screenTarget = Constants.ScreenUserTypeChoice;
        if (isTeacher !== undefined) {
            // On va montrer le choix du type d'utilisateur
            screenTarget = Constants.ScreenOnboarding;
        }
        const { componentId, history } = props;
        Navigation.showModal({
            componentId,
            history,
            name: screenTarget,
            passProps: {
                onDismiss:Platform.OS === "web" ? undefined : checkUserConnexion,
                isTeacher
            }
        });
    }

    // UI
    const loadingView = isLoading === true ? <ActivityIndicator color={appTheme.secondaryColor} /> : <View />;

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        componentId: "",
        showNavBar: false,
        showMenu: false
    });

    const footer = () => {
        return (<View style={styles.footer}>
            <Text style={{ ...styles.footerText, color: appTheme.secondaryColor }}>
                {i18n.t("app.title", { version, build })}
            </Text>
        </View>);
    }

    return (
        <Main mainOptions={mainOptions} >
            <View style={[styles.main, { backgroundColor: appTheme.primaryColor }]}>
                <Image source={images.imgAppLogo} style={styles.logo} resizeMode="contain" />
                {loadingView}
                {footer()}
            </View>
        </Main>
    )
}

export default Splashscreen;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        width: 240,
        height: 240
    },
    footer: {
        position: "absolute",
        bottom: 40
    },
    footerText: {
    }
});