import Firebase from "firebase";
import { DBCollectionUsers, DBCollectionCodes } from '../../shared/utils/Constants';

const firebaseConfig = {
    apiKey: "AIzaSyD5fQrShi1qQCgbvQubqA4r6yQzcmd_qlI",
    authDomain: "lili-c7853.firebaseapp.com",
    databaseURL: "https://lili-c7853.firebaseio.com",
    projectId: "lili-c7853",
    storageBucket: "lili-c7853.appspot.com",
    messagingSenderId: "908054617461",
    appId: "1:908054617461:web:473c3eaf0a96c3af85073c",
    measurementId: "G-P17JN801Y5"
};

const devFirebaseConfig = {
    apiKey: "AIzaSyAo3HkaSmxlMO9YlMg7qIBOOLU70ENJWcQ",
    authDomain: "lili-dev-608f0.firebaseapp.com",
    databaseURL: "https://lili-dev-608f0.firebaseio.com",
    projectId: "lili-dev-608f0",
    storageBucket: "lili-dev-608f0.appspot.com",
    messagingSenderId: "766371018901",
    appId: "1:766371018901:web:dcdff94cdbace262f3039c"
}

//export const app = initializeApp(devFirebaseConfig);
//export const app = Firebase.initializeApp(devFirebaseConfig);
export const app = Firebase.initializeApp(firebaseConfig);
export const db = Firebase.firestore(app);

/**
 * 
 * USER
 */
export const getUserWithId = async (data: { user_id: string }) => {
    const { user_id } = data;
    try {
        const querySnapshot = await db.collection(DBCollectionUsers).doc(user_id).get();
        if (querySnapshot.exists === true) {
            const result = querySnapshot.data();
            return result;
        } else {
            return undefined;
        }
    } catch (error) {
        throw error;
    }
}

export const addUserWithData = async (data: { user_id: string, user_email: string, school_name: string, school_zipcode: string, opt_in: boolean, sub_id?: string, duration_in_month?: number, addCode?: boolean }) => {
    try {
        const { user_id, user_email, school_name, school_zipcode, opt_in, sub_id, duration_in_month = 1, addCode = false } = data;
        const today = new Date();
        let userData = {
            creation_date: today.getTime(),
            email: user_email,
            optIn: opt_in,
            sub: undefined
        }
        if (sub_id !== undefined) {
            let expires_date = new Date();
            expires_date.setMonth(today.getMonth() + duration_in_month);
            userData.sub = {
                creationDate: today.getTime(),
                start_date: today.getTime(),
                expires_date: expires_date.getTime(),
                auto_renewing: false,
                origin: "lili",
                product_id: sub_id,
            }
        }
        // On va créer l'utilisateur
        const createUserResult = await db.collection(DBCollectionUsers).doc(user_id).set(userData);
        // On va lui affecter un code
        const addCodeResult = await createTeacherCode({ user_id, user_email, school_name, school_zipcode });
        return addCodeResult;
    } catch (error) {
        throw error;
    }
}

export const addSubToUser = async (data: { user_id: string, sub_id: string, duration_in_month: number }) => {
    const { user_id, sub_id, duration_in_month } = data;
    const start_date = new Date();
    let expires_date = new Date();
    expires_date.setMonth(start_date.getMonth() + duration_in_month);
    try {
        const updateResult = await db.collection(DBCollectionUsers).doc(user_id).update({
            sub: {
                creationDate: start_date.getTime(),
                start_date: start_date.getTime(),
                expires_date: expires_date.getTime(),
                auto_renewing: false,
                origin: "lili",
                product_id: sub_id,
            }
        });
        return updateResult;
    } catch (error) {
        throw error;
    }
}

export const createTeacherCode = async (data: { user_id: string, user_email: string, school_name: string, school_zipcode: string }) => {
    try {
        const { user_id, user_email, school_name, school_zipcode } = data;
        const randomCode = Math.floor(100000 + Math.random() * 900000);
        const codeCreationResult = await db.collection(DBCollectionCodes).add({
            classroom: "lili",
            code: randomCode,
            count: 1,
            creation_date: new Date().getTime(),
            email: user_email,
            expirationDate: -1,
            firstname: "",
            lastname: "",
            grade: [],
            school: school_name + ", " + school_zipcode,
            status: "activated",
            type: "teacher",
            validity: -1
        });
        console.log("codeCreationResult", codeCreationResult.id);
        const linkCodeToUserResult = await db.collection(DBCollectionUsers).doc(user_id).update({
            code: {
                code: randomCode,
                expirationDate: -1,
                key: codeCreationResult.id
            }
        });
        return linkCodeToUserResult;
    } catch (error) {
        throw error;
    }
}

export function getCollection({ collection, query }) {
    return new Promise(function (resolve, reject) {
        db.collection(collection).get().then((querySnapshot) => {
            if (querySnapshot.empty === false) {
                let result = [];
                for (const aDocIndex in querySnapshot.docs) {
                    if (Object.hasOwnProperty.call(querySnapshot.docs, aDocIndex)) {
                        const anElement = querySnapshot.docs[aDocIndex];
                        let aDoc = {
                            key: anElement.id,
                            data: anElement.data()
                        }
                        result.push(aDoc);
                    }
                }
                resolve(result);
            } else {
                resolve([]);
            }

        }).catch((error) => {
            reject(error);
        });
    });
}

export function getDocument({ collection, doc }) {
    return new Promise(function (resolve, reject) {

    });
}

export function setDocument({ collection, key, content }) {
    return new Promise(function (resolve, reject) {

    });
}