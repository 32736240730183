import React from "react"
import { Platform, Image, View } from "react-native"

const SVGView = (props: { Component: any, width: number, height: number, color?: string }) => {
   const { Component, width, height, color } = props;
   if (Platform.OS === "web") {
      return (
         <Image source={props.Component} style={{ width, height, tintColor: color }} resizeMode="contain" />
      )
   } else {
      return (
         <Component width={width} height={height} fill={color} />
      )
   }
}

export default SVGView;