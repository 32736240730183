import React from 'react';
import { render } from 'react-dom';

import {
    View,
    Platform
} from 'react-native';

import * as Constants from './src/shared/utils/Constants';

// Components
import ActivitiesTags from "./src/shared/components/Activities/ActivitiesTags";
import AppMenu from "./src/shared/components/Menu/AppMenu";
import Authentication from "./src/shared/components/Authentication/Authentication";
import Onboarding from "./src/shared/components/Authentication/Onboarding";
import Splashscreen from './src/shared/components/Splashcreen/Splashscreen';
import SignIn from "./src/shared/components/Authentication/SignIn";
import SignUp from "./src/shared/components/Authentication/SignUp";
import TeacherForm from "./src/shared/components/SchoolCommunication/TeacherForm";
import UserTypeChoice from './src/shared/components/Authentication/UserTypeChoice';
import UserProfile from "./src/shared/components/Profile/UserProfile";

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const App = () => {
    return (
            <Router>
                <Switch>
                    <View style={{ width: "100%", height: "100%" }}>
                        <Route exact path={Constants.ScreenActivitiesTags} component={ActivitiesTags} />
                        <Route exact path={Constants.ScreenAppMenu} component={AppMenu} />
                        <Route exact path={Constants.ScreenAuthentication} component={Authentication} />
                        <Route exact path={Constants.ScreenOnboarding} component={Onboarding} />
                        <Route exact path={Constants.ScreenSplashscreen} component={Splashscreen} />
                        <Route exact path={Constants.ScreenSignIn} component={SignIn} />
                        <Route exact path={Constants.ScreenSignUp} component={SignUp} />
                        <Route exact path={Constants.ScreenTeacherForm} component={TeacherForm} />
                        <Route exact path={Constants.ScreenUserTypeChoice} component={UserTypeChoice} />
                        <Route exact path={Constants.ScreenUserProfile} component={UserProfile} />
                    </View>
                </Switch>
            </Router>
    )
};

render(<App />, document.getElementById("root"));