import React from "react";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import {
    ActivityIndicator,
    Image,
    Platform,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    SafeAreaView,
    Dimensions,
    Keyboard,
    Modal
} from 'react-native';

import AppTheme from "../../utils/Theme";
import { pixelPerfect, pixelPerfectForFont } from "../../utils/PixelPerfect";
import Button from "../../designSystem/Button";


const Alert = forwardRef((props: any, ref: any) => {

    const [isVisible, setIsVisible] = useState(false);

    const appTheme: AppTheme = new AppTheme();

    const hideTheAlert = () => {
        setAlertContent({
            title: "",
            body: "",
            primaryButtonTitle: "OK",
            onPressPrimaryButton: hideTheAlert,
            secondaryButtonTitle: "OK",
            onPressSecondaryButton: hideTheAlert,
            isVisible: false
        });
    }

    const [alertContent, setAlertContent] = useState({
        title: "",
        body: "",
        primaryButtonTitle: "OK",
        onPressPrimaryButton: hideTheAlert,
        secondaryButtonTitle: "OK",
        onPressSecondaryButton: hideTheAlert,
        isVisible: false
    });

    // ComponentDidMount
    useEffect(() => {
    }, []);

    useImperativeHandle(ref, () => ({

        showAlert(data: {
            title: string,
            body?: string,
            primaryButtonTitle?: string,
            onPressPrimaryButton?: (() => void) | undefined,
            secondaryButtonTitle?: string,
            onPressSecondaryButton?: (() => void) | undefined
        }) {
            const {
                title = "",
                body = "",
                primaryButtonTitle = "OK",
                onPressPrimaryButton = hideTheAlert,
                secondaryButtonTitle = "OK",
                onPressSecondaryButton = hideTheAlert,
            } = data;
            setAlertContent({
                title,
                body,
                primaryButtonTitle,
                onPressPrimaryButton,
                secondaryButtonTitle,
                onPressSecondaryButton,
                isVisible: true
            });
        },

        hideAlert() {
            hideTheAlert();
        }
    }));

    return (
        <Modal
            transparent={true}
            visible={alertContent.isVisible}
            style={{ width: Dimensions.get("window").width, height: Dimensions.get("window").height }}>
            <View style={{ width: Dimensions.get("window").width, height: Dimensions.get("window").height, justifyContent: "center", alignItems: "center", backgroundColor: appTheme.alertOverlayColor }}>
                <View style={{ width: pixelPerfect(200), padding: pixelPerfect(20), borderRadius: pixelPerfect(10), backgroundColor: appTheme.alertBackgroundColor }}>
                    <View style={{ marginBottom: pixelPerfect(10) }}>
                        <Text style={{ fontFamily: appTheme.primaryFont, fontSize: pixelPerfectForFont(12), fontWeight: "600" }}>{alertContent.title}</Text>
                        <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: pixelPerfectForFont(10) }}>{alertContent.body}</Text>
                    </View>
                    <Button onPress={alertContent.onPressPrimaryButton} title={alertContent.primaryButtonTitle} />
                </View>
            </View>
        </Modal>
    )
});

export default Alert;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center"
    },
    navBar: {
        width: Dimensions.get("window").width,
        height: 60
    }
});