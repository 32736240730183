import React from "react";
import { useState, useEffect } from "react";
import * as Navigation from "../../../specific/Navigation";
import Main from "../../../specific/components/Main/Main";
import i18n from "../../utils/Localization/Localization";
import { pixelPerfect, pixelPerfectForFont } from "../../utils/PixelPerfect";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image,
    ScrollView,
    Modal,
    Alert
} from 'react-native';

import AppTheme, { Theme } from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// UI
import Button from "../../designSystem/Button";
import RadioButton from "../../designSystem/RadioButton";
import TextInput from "../../designSystem/TextInput";

// Assets
import Images from "../../../specific/utils/Images";

// Services
import { signInWithEmailAndPassword } from "../../services/Auth";
import { isUserATeacher } from "../../utils/LocalStorage";

interface Props {
    name: string;
}

const SignUp = (props: any) => {

    const [isLoading, setIsLoading] = useState(true);
    const [isTeacher, setIsTeacher] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertAttributes, setAlertAttributes] = useState({ title: "", body: "" });

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    // ComponentDidMount
    useEffect(() => {

    }, []);

    const checkUserType = async () => {
        const isUserTeacher: boolean | undefined = await isUserATeacher();
        if (isUserTeacher !== undefined) {
            setIsTeacher(isUserTeacher);
        }
    }

    const connectUser = () => {
        signInWithEmailAndPassword({
            email: emailAddress,
            password: password
        }).then(() => {
            const { componentId, history } = props;
            Navigation.pop({
                componentId,
                history
            });
        }).catch((error) => {
            setAlertAttributes({
                title: "Une erreur est survenue",
                body: error.toString()
            });
            setShowAlert(true);
        });
    }

    const signUpUser = () => {
        const { componentId, history } = props;
        Navigation.push({
            componentId,
            history,
            name: Constants.ScreenSignUp
        });
    }

    const forgotPasswordRequest = () => {
        const { componentId, history } = props;
        Navigation.push({
            componentId,
            history,
            name: Constants.ScreenUserProfile
        });
    }

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        componentId: "",
        showNavBar: false,
        showMenu: false
    });

    const footer = () => {
        if (Platform.OS !== "web") {
            return <View />;
        }
        return (<View style={styles.footer}>
            <Text style={{ ...styles.footerText, color: appTheme.secondaryColor }}>
                Jidokapp - Build 1
            </Text>
        </View>);
    }

    const onChangeEmailAddress = (emailAddress: string) => {
        setEmailAddress(emailAddress);
    }

    const onChangePassword = (password: string) => {
        setPassword(password);
    }

    const getSignInBoxSize = () => {
        const screenWidth = appTheme.getFullAppWidth();
        const screenHeight = appTheme.getFullScreenHeight();
        return ({
            width: appTheme.getFullAppWidth() - pixelPerfect(20),
            height: (appTheme.getFullScreenHeight() / 2) - pixelPerfect(20)
        })
        if (screenWidth < screenHeight) {
            return ({
                width: appTheme.getFullAppWidth() - pixelPerfect(20),
                height: (appTheme.getFullScreenHeight() / 2) - pixelPerfect(20)
            })
        }
        return ({
            width: (appTheme.getFullAppWidth() / 2) - pixelPerfect(20),
            height: appTheme.getFullScreenHeight() - pixelPerfect(40)
        })
    }

    const getHeadeImage = () => {
        if (isTeacher === true) {
            return images.icLiliAtSchoolCircle;
        }
        return images.icLiliAtHomeCircle;
    }

    const onRadioButtonPress = (data) => {
        console.log(data);
    }

    const openGeneralConditions = () => {

    }

    const showSignInScreen = () => {
        if (props.onFlipRequest !== undefined) {
            props.onFlipRequest();
        }
    }

    return (
        <View style={[styles.main]}>
            <View style={{ width: appTheme.getFullAppWidth(), flex: 1 }}>
                <View style={{ alignItems: "center", justifyContent: "center", paddingHorizontal: pixelPerfect(20), paddingTop: pixelPerfect(20), flexDirection: appTheme.getFlexDirectionForSplitScreen() }}>
                    <Image source={getHeadeImage()} style={styles.logo} resizeMode="contain" />
                    <Text style={{ fontSize: pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, fontWeight: "600", color: appTheme.titleColor, marginBottom: pixelPerfect(10), paddingTop: pixelPerfect(10), paddingHorizontal: pixelPerfect(20) }}>
                        {i18n.t("authentication.signUp.liliAtHome.title").toLocaleUpperCase()}
                    </Text>
                </View>
                <View style={{ flex: 1 }}>
                    <ScrollView style={{ flex: 1 }}>
                        <View style={{ flex: 1, alignItems: "center", justifyContent: "center", marginTop: pixelPerfect(10) }}>
                            <View style={{ width: pixelPerfect(310), alignItems: "center", justifyContent: "center" }}>
                                <View style={{
                                    borderRadius: pixelPerfect(20),
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: pixelPerfect(20),
                                    width: pixelPerfect(260),
                                    backgroundColor: appTheme.white,
                                    paddingVertical: pixelPerfect(20)
                                }}>
                                    <View style={{ marginBottom: pixelPerfect(10) }}>
                                        <TextInput
                                            label={i18n.t("authentication.signUp.liliAtHome.form.emailPlaceholder")}
                                            style={[{ borderColor: appTheme.secondaryColor, width: pixelPerfect(220) }]}
                                            onChangeText={onChangeEmailAddress}
                                            value={emailAddress}
                                            keyboardType="email-address"
                                            autoCapitalize="none"
                                        />
                                        <TextInput
                                            label={i18n.t("authentication.signUp.liliAtHome.form.passwordPlaceholder")}
                                            password={true}
                                            style={[{ borderColor: appTheme.secondaryColor }]}
                                            onChangeText={onChangePassword}
                                            value={password}
                                            secureTextEntry={true}
                                            autoCapitalize="none"
                                        />
                                    </View>
                                    <View style={{ width: pixelPerfect(220), alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "row", marginTop: pixelPerfect(10) }}>
                                        <View>
                                            <RadioButton style={{ width: pixelPerfect(220) }} radioButtonStyle={{ marginTop: pixelPerfect(10) }} onPress={onRadioButtonPress}>
                                                <View style={{ flex: 1 }}>
                                                    <View style={{ alignItems: "flex-end", justifyContent: "flex-start", flexDirection: "row" }}>
                                                        <Image source={images.icCoachManualYellow} style={{ width: pixelPerfect(50), height: pixelPerfect(50) }} resizeMode="contain" />
                                                        <Text style={{ flex: 1, fontFamily: appTheme.primaryFont, fontWeight: "700", fontSize: pixelPerfectForFont(10) }}>
                                                            {i18n.t("authentication.signUp.liliAtHome.form.radioButtons.coachManual.firstPart")}
                                                        </Text>
                                                    </View>
                                                    <Text style={{ flex: 1, fontFamily: appTheme.secondaryFont, fontSize: pixelPerfectForFont(10) }}>
                                                        {i18n.t("authentication.signUp.liliAtHome.form.radioButtons.coachManual.secondPart")}
                                                    </Text>
                                                </View>
                                            </RadioButton>
                                        </View>
                                    </View>
                                    <View style={{ width: pixelPerfect(220), alignItems: "flex-start", marginTop: pixelPerfect(10) }}>
                                        <RadioButton style={{ width: pixelPerfect(220) }}>
                                            <Text style={{ flex: 1, fontFamily: appTheme.secondaryFont, fontSize: pixelPerfectForFont(10) }}>
                                                {i18n.t("authentication.signUp.liliAtHome.form.radioButtons.newsletter")}
                                            </Text>
                                        </RadioButton>
                                    </View>
                                    <View style={{ marginTop: pixelPerfect(20) }}>
                                        <Button
                                            title={i18n.t("authentication.signUp.liliAtHome.buttons.signUp")} />
                                    </View>
                                    <TouchableOpacity onPress={openGeneralConditions} style={{ marginTop: pixelPerfect(20) }}>
                                        <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: pixelPerfectForFont(9), textAlign: "center" }}>
                                            {i18n.t("authentication.signUp.liliAtHome.buttons.cgu.firstPart")}
                                        </Text>
                                        <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: pixelPerfectForFont(9), textAlign: "center", textDecorationLine: "underline" }}>
                                            {i18n.t("authentication.signUp.liliAtHome.buttons.cgu.secondPart")}
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                                <Image source={images.icBird} style={{ position: "absolute", top: -pixelPerfect(8), right: 0, width: pixelPerfect(75), height: pixelPerfect(72) }} resizeMode="contain" />
                            </View>
                            <View style={{ marginTop: pixelPerfect(20) }}>
                                <Button
                                    onPress={showSignInScreen}
                                    textStyle={{ color: appTheme.white }}
                                    link={true}
                                    title={i18n.t("authentication.signUp.liliAtHome.buttons.signIn")} />
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </View>
        </View>
    )
}

export default SignUp;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        width: pixelPerfect(70),
        height: pixelPerfect(70),
    },
    footer: {
        position: "absolute",
        bottom: 40
    },
    footerText: {
    },
    input: {
        height: pixelPerfect(40),
        marginVertical: pixelPerfect(5),
        borderWidth: pixelPerfect(1),
        padding: pixelPerfect(10),
        width: pixelPerfect(260),
        borderRadius: pixelPerfect(10),
        fontSize: pixelPerfectForFont(12)
    },
    button: {
        marginTop: pixelPerfect(10),
        height: pixelPerfect(40),
        justifyContent: "center",
        alignItems: "center",
        width: pixelPerfect(260),
        borderRadius: pixelPerfect(10)
    },
    textButton: {
        height: pixelPerfect(40),
        justifyContent: "center",
        alignItems: "flex-end",
        width: pixelPerfect(260),
        borderRadius: pixelPerfect(10)
    },
    textButtonTitle: {
        fontSize: pixelPerfectForFont(13),
        textDecorationLine: "underline"
    },
    buttonTitle: {
        fontSize: pixelPerfectForFont(13)
    }
});