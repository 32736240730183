import AsyncStorage from '@react-native-async-storage/async-storage';

const user_type_key = "user_type_key";

export const wipeData = () => {
    AsyncStorage.removeItem(user_type_key);
}

const storeData = async (data: { key: string, value: string }) => {
    const { key, value } = data;
    try {
        await AsyncStorage.setItem(key, value);
        return true;
    } catch (e) {
        return undefined;
    }
}

const getData = async (data: {key: string}) => {
    const { key } = data;
    try {
        const value = await AsyncStorage.getItem(key)
        if (value !== null) {
            // value previously stored
            return value;
        }
    } catch (e) {
        // error reading value
        return undefined;
    }
}

/**
 * USER TYPE : TEACHER OR PARENT
 */
export const getUserType = async () => {
    const userType = await getData({
        key: user_type_key
    });
    return userType;
}

export const isUserATeacher = async () => {
    const userType = await getUserType();
    if (userType !== undefined) {
        return (userType === "teacher");
    }
    return userType;
}

export const setUserType = async (data: {defineAsTeacher: boolean}) => {
    const { defineAsTeacher } = data;
    const userTypeValue = defineAsTeacher === true ? "teacher" : "parent";
    const storeUserTypeStatus = await storeUserType({
        userType: userTypeValue
    });
}

export const storeUserType = async (data:{ userType: string} ) => {
    const { userType } = data;
    const storeStatus = await storeData({
        key: user_type_key,
        value: userType
    });
}