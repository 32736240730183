import React from "react";
import { useState, useEffect } from "react";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image
} from 'react-native';

import AppTheme from "../../../utils/Theme";
import * as Constants from "../../../utils/Constants";
import MainOptions from "../../../models/MainOptions";
import { pixelPerfect, pixelPerfectForFont } from "../../../utils/PixelPerfect";

// Assets
import Images from "../../../../specific/utils/Images";

// SVG
import i18n from "../../../utils/Localization/Localization";

interface Props {
    name: string;
}

const OnboardingTeacherPageOne = (props: any) => {

    const [isLoading, setIsLoading] = useState(true);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    // ComponentDidMount
    useEffect(() => {

    }, []);

    const mainImageHeight = appTheme.getFullScreenHeight() / 3;

    return (
        <View style={[styles.main, { width: appTheme.getFullAppWidth(), padding: pixelPerfect(10) }]}>
            <Image source={images.icLiliAtSchoolCircle} style={{ width: pixelPerfect(90), height: pixelPerfect(90) }} resizeMode="contain" />
            <View style={{ flex:1, justifyContent:"space-evenly", alignItems:"center", paddingBottom:pixelPerfect(125) }}>
                <Text style={[styles.title, { fontFamily: appTheme.primaryFont, marginTop: pixelPerfect(20), color: appTheme.schoolColor }]}>{i18n.t("onboarding.teacher.pageOne.title").toLocaleUpperCase()}</Text>
                <Text style={[styles.subtitle, { fontFamily: appTheme.primaryFont, marginTop: pixelPerfect(10), color: appTheme.schoolColor }]}>{i18n.t("onboarding.teacher.pageOne.subtitle")}</Text>
                <View style={{ marginTop: pixelPerfect(10) }}>
                    <Image source={images.illOnboardingTeacherPageOne} style={{ width: appTheme.getFullAppWidth(), height: mainImageHeight }} resizeMode="contain" />
                </View>
                <Text style={[styles.legend, { fontFamily: appTheme.secondaryFont, marginTop: pixelPerfect(10), color: appTheme.schoolColor }]}>{i18n.t("onboarding.teacher.pageOne.legend")}</Text>
            </View>
        </View>
    )
}

export default OnboardingTeacherPageOne;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        textAlign: "center",
        fontSize: pixelPerfectForFont(10),
        fontWeight: "500",
        letterSpacing: 1
    },
    subtitle: {
        textAlign: "center",
        fontSize: pixelPerfectForFont(10),
        fontWeight: "500",
        letterSpacing: 1
    },
    legend: {
        textAlign: "center",
        fontSize: pixelPerfectForFont(10),
        letterSpacing: 1
    }
});