import React from "react";
import { useState, useEffect } from "react";
import * as Navigation from "../../../specific/Navigation";
import Main from "../../../specific/components/Main/Main";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image,
    FlatList,
    Modal
} from 'react-native';

import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// Assets
import Images from "../../../specific/utils/Images";

// Services
import { getActivities, saveActivities } from "../../services/Database";
import { pixelPerfect } from "../../utils/PixelPerfect";

interface Props {
    name: string;
}

const ActivitiesTags = (props: any) => {

    const [isLoading, setIsLoading] = useState(true);
    const [activities, setActivities] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertAttributes, setAlertAttributes] = useState({title:"", body:""});
    const [tags, setTags] = useState([]);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const cellWidth: number = appTheme.getFullAppWidth() - pixelPerfect(10);
    const tagsButtonWidth: number = (cellWidth - pixelPerfect(40)) / 2;

    // ComponentDidMount
    useEffect(() => {
        getActivities().then((result: any) => {
            console.log(result);
            if (result !== undefined) {
                setActivities(result);
                setIsLoading(false);
            }
        });
    }, []);

    const recordTags = (item:{data: any, key:string}) => {
        const { data, key } = item;
        let updateActi = {...data};
        updateActi.superTags = tags;
        updateActi.last_update = new Date().getTime();
        saveActivities({
            key:key,
            content:updateActi
        });
    }

    const toggleTag = (tagId: string) => {
        let selectedTags = [...tags];
        const indexOfTag = selectedTags.indexOf(tagId);
        if (indexOfTag !== -1) {
            selectedTags.splice(indexOfTag, 1);
        } else {
            selectedTags.push(tagId);
        }
        setTags(selectedTags);
    }

    const activityCell = ({ item, index }) => {
        if (item !== undefined) {
            const { data, key } = item;
            return (
                <View style={{ width: appTheme.getFullAppWidth(), marginBottom: pixelPerfect(40), justifyContent: "center", alignItems: "center" }}>
                    <Image source={{ uri: data.imgUrl }} style={{ width: cellWidth, height: pixelPerfect(100), borderRadius: pixelPerfect(10) }} />
                    <Text style={{ textAlign: "center", paddingVertical: pixelPerfect(10), fontWeight: "bold" }}>{key+" - "+data.title.fr}</Text>
                    <View style={{ marginTop: pixelPerfect(10), flexDirection: "row", justifyContent: "space-evenly", width: cellWidth }}>
                    <TouchableOpacity onPress={() => toggleTag("emotionsTaming")}
                        style={{
                            width: tagsButtonWidth,
                            height: pixelPerfect(30),
                            borderWidth: pixelPerfect(1),
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: pixelPerfect(15),
                            borderColor: appTheme.secondaryColor,
                            backgroundColor: tags.includes("emotionsTaming") ? appTheme.secondaryColor : ((data.superTags !== undefined) && (data.superTags.includes("emotionsTaming") === true)) ? appTheme.secondaryBackgroundColor : appTheme.primaryColor
                        }}>
                            <Text style={{ textAlign: "center" }}>Apprivoiser ses émotions</Text>
                        </TouchableOpacity>
                        <TouchableOpacity 
                        onPress={() => toggleTag("focus")}
                        style={{
                            width: tagsButtonWidth,
                            height: pixelPerfect(30),
                            borderWidth: pixelPerfect(1),
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: pixelPerfect(15),
                            borderColor: appTheme.secondaryColor,
                            backgroundColor: tags.includes("focus") ? appTheme.secondaryColor : ((data.superTags !== undefined) && (data.superTags.includes("focus") === true)) ? appTheme.secondaryBackgroundColor : appTheme.primaryColor
                        }}>
                            <Text style={{ textAlign: "center" }}>Pose ton attention</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginTop: pixelPerfect(10), flexDirection: "row", justifyContent: "space-evenly", width: cellWidth }}>
                    <TouchableOpacity onPress={() => toggleTag("errorTaming")}
                        style={{
                            width: tagsButtonWidth,
                            height: pixelPerfect(30),
                            borderWidth: pixelPerfect(1),
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: pixelPerfect(15),
                            borderColor: appTheme.secondaryColor,
                            backgroundColor: tags.includes("errorTaming") ? appTheme.secondaryColor : ((data.superTags !== undefined) && (data.superTags.includes("errorTaming") === true)) ? appTheme.secondaryBackgroundColor : appTheme.primaryColor
                        }}>
                            <Text style={{ textAlign: "center" }}>Dompter l’erreur</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => toggleTag("speak")}
                        style={{
                            width: tagsButtonWidth,
                            height: pixelPerfect(30),
                            borderWidth: pixelPerfect(1),
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: pixelPerfect(15),
                            borderColor: appTheme.secondaryColor,
                            backgroundColor: tags.includes("speak") ? appTheme.secondaryColor : ((data.superTags !== undefined) && (data.superTags.includes("speak") === true)) ? appTheme.secondaryBackgroundColor : appTheme.primaryColor
                        }}>
                            <Text style={{ textAlign: "center" }}>Oser à l’oral</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginTop: pixelPerfect(10), flexDirection: "row", justifyContent: "space-evenly", width: cellWidth }}>
                    <TouchableOpacity onPress={() => toggleTag("criticalThinking")}
                        style={{
                            width: tagsButtonWidth,
                            height: pixelPerfect(30),
                            borderWidth: pixelPerfect(1),
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: pixelPerfect(15),
                            borderColor: appTheme.secondaryColor,
                            backgroundColor: tags.includes("criticalThinking") ? appTheme.secondaryColor : ((data.superTags !== undefined) && (data.superTags.includes("criticalThinking") === true)) ? appTheme.secondaryBackgroundColor : appTheme.primaryColor
                        }}>
                            <Text style={{ textAlign: "center" }}>Exercer son sens critique</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => toggleTag("collective")}
                        style={{
                            width: tagsButtonWidth,
                            height: pixelPerfect(30),
                            borderWidth: pixelPerfect(1),
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: pixelPerfect(15),
                            borderColor: appTheme.secondaryColor,
                            backgroundColor: tags.includes("collective") ? appTheme.secondaryColor : ((data.superTags !== undefined) && (data.superTags.includes("collective") === true)) ? appTheme.secondaryBackgroundColor : appTheme.primaryColor
                        }}>
                            <Text style={{ textAlign: "center" }}>La jouer collectif</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginTop: pixelPerfect(10), flexDirection: "row", justifyContent: "space-evenly", width: cellWidth }}>
                    <TouchableOpacity onPress={() => toggleTag("selfEsteem")}
                        style={{
                            width: tagsButtonWidth,
                            height: pixelPerfect(30),
                            borderWidth: pixelPerfect(1),
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: pixelPerfect(15),
                            borderColor: appTheme.secondaryColor,
                            backgroundColor: tags.includes("selfEsteem") ? appTheme.secondaryColor : ((data.superTags !== undefined) && (data.superTags.includes("selfEsteem") === true)) ? appTheme.secondaryBackgroundColor : appTheme.primaryColor
                        }}>
                            <Text style={{ textAlign: "center" }}>Croise en soi</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => toggleTag("sleep")}
                        style={{
                            width: tagsButtonWidth,
                            height: pixelPerfect(30),
                            borderWidth: pixelPerfect(1),
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: pixelPerfect(15),
                            borderColor: appTheme.secondaryColor,
                            backgroundColor: tags.includes("sleep") ? appTheme.secondaryColor : ((data.superTags !== undefined) && (data.superTags.includes("sleep") === true)) ? appTheme.secondaryBackgroundColor : appTheme.primaryColor
                        }}>
                            <Text style={{ textAlign: "center" }}>Sommeil</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginTop: pixelPerfect(30), flexDirection: "row", justifyContent: "space-evenly", width: cellWidth }}>
                        <TouchableOpacity 
                        onPress={() => recordTags(item)}
                        style={{
                            width: tagsButtonWidth,
                            height: pixelPerfect(40),
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: pixelPerfect(15),
                            backgroundColor: appTheme.secondaryColor
                        }}>
                            <Text style={{ textAlign: "center", color: appTheme.primaryColor, fontWeight: "bold" }}>Valider</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            )
        }
        return <View />;
    }

    const keyExtractor = (item: any, index: number) => {
        return index.toString();
    }

    const getActivitiesList = () => {
        return (
            <FlatList
                pagingEnabled={true}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                style={{ flex: 1, width: appTheme.getFullAppWidth(), backgroundColor: appTheme.primaryColor }}
                data={activities}
                renderItem={activityCell}
                keyExtractor={keyExtractor} />
        )
    }

    // UI
    const loadingView = isLoading === true ? <ActivityIndicator color={appTheme.secondaryColor} /> : <View />;
    const resultView = isLoading === false ? getActivitiesList() : <View />;
    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        componentId: "",
        showNavBar: false,
        showMenu: false
    });

    return (
        <Main mainOptions={mainOptions} >
            <View style={[styles.main, { backgroundColor: appTheme.primaryColor }]}>
                <Image source={images.imgAppLogo} style={styles.logo} resizeMode="contain" />
                {loadingView}
                {resultView}
            </View>
            <Modal
                animationType="slide"
                transparent={true}
                visible={showAlert}>
                <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: appTheme.alertOverlayColor }}>
                    <View style={{ width: pixelPerfect(260), paddingVertical: pixelPerfect(20), paddingHorizontal: pixelPerfect(10), backgroundColor: appTheme.alertBackgroundColor, borderRadius: pixelPerfect(10) }}>
                        <Text>{alertAttributes.title}</Text>
                        <Text>{alertAttributes.body}</Text>
                        <TouchableOpacity
                            onPress={() => {
                                setShowAlert(false);
                            }}>
                            <Text>OK</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
        </Main>
    )
}

export default ActivitiesTags;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        width: pixelPerfect(50),
        height: pixelPerfect(50)
    },
    footer: {
        position: "absolute",
        bottom: 40
    },
    footerText: {
    }
});