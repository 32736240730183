import React from "react";

import {
    Dimensions,
    StyleSheet,
    Pressable,
    Text,
    ActivityIndicator
} from 'react-native';

import { pixelPerfect, pixelPerfectForFont, pixelPerfectForLineHeight } from "../utils/PixelPerfect";
import AppTheme, { Theme } from "../utils/Theme";

export const horizontalMargin = pixelPerfect(20);

const Button = (props: {
    disabled?: boolean,
    onLongPress?: ((event: any) => void),
    onPress?: ((event: any) => void),
    inversed?: boolean,
    isLoading?: boolean,
    link?: boolean,
    style?: any,
    title?: string,
    textStyle?: any
}) => {

    const {
        disabled,
        onLongPress,
        onPress,
        inversed = false,
        isLoading,
        link,
        style,
        title,
        textStyle
    } = props;

    const appTheme: AppTheme = new AppTheme();

    const isLinkButton = ((link !== undefined) && (link === true));
    const buttonStyle = isLinkButton === false ? styles.pressable : styles.link;
    let backgroundColor = isLinkButton === true ? "transparent" : ((style !== undefined) && (style.backgroundColor !== undefined)) ? style.backgroundColor : appTheme.buttonBackgroundColor;
    let pressedBackgroundColor = ((style !== undefined) && (style.pressedBackgroundColor !== undefined)) ? style.pressedBackgroundColor : backgroundColor + "33";
    let buttonTitle = title !== undefined ? title : 'OK';
    let titleColor = ((textStyle !== undefined) && (textStyle.color !== undefined)) ? textStyle.color : isLinkButton === true ? appTheme.textColor : appTheme.white;
    let titleFontSize = ((textStyle !== undefined) && (textStyle.fontSize !== undefined)) ? textStyle.fontSize : pixelPerfectForFont(11);
    let textDecorationLine = isLinkButton === true ? "underline" : "none";
    let contextStyle = {
        backgroundColor,
        color: titleColor
    };

    const buttonContent = ((isLoading !== undefined) && (isLoading === true)) ?
        <ActivityIndicator color={titleColor} /> :
        <Text style={[styles.title, textStyle, { color: titleColor, textDecorationLine, fontFamily: appTheme.primaryFont, fontSize: titleFontSize, fontWeight: "500", paddingHorizontal:pixelPerfect(10) }]}>{buttonTitle.toLocaleUpperCase()}</Text>;
    const isDisabled = disabled !== undefined ? disabled : false;

    return (
        <Pressable
            disabled={isDisabled}
            onLongPress={onLongPress}
            onPress={onPress}
            style={({ pressed }) => [
                {
                    opacity: pressed === true ? 0.5 : 1
                },
                style,
                buttonStyle,
                contextStyle
            ]}>
            {buttonContent}
        </Pressable>
    )

}

export default Button;

const styles = StyleSheet.create({
    pressable: {
        paddingVertical: pixelPerfect(10),
        borderRadius: pixelPerfect(60),
        justifyContent: "center",
        alignItems: "center"
    },
    link: {
        borderRadius: pixelPerfect(20),
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'transparent'
    },
    shadowed: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.30,
        shadowRadius: 4.65,

        elevation: 8,
    },
    pressed_shadowed: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,

        elevation: 4,
    },
    title: {
        fontSize: pixelPerfectForFont(14),
        textAlign: "center"
    }
});