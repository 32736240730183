class MainOptions {
    canBeDismissed: boolean;
    canGoBack: boolean;
    componentId: string;
    history: any;
    showNavBar: boolean;
    showMenu: boolean;
    safeArea: boolean;
    backgroundColor: string;

    constructor(options: {
        canBeDismissed?: boolean,
        canGoBack?: boolean,
        componentId?: string, 
        history?: any,
        showNavBar?: boolean,
        showMenu?: boolean,
        safeArea?: boolean,
        backgroundColor?: string
    }) {
        this.canBeDismissed = options.canBeDismissed ?? false;
        this.canGoBack = options.canGoBack ?? true;
        this.componentId = options.componentId ?? "";
        this.history = options.history ?? {};
        this.showNavBar = options.showNavBar ?? false;
        this.showMenu = options.showMenu ?? false;
        this.safeArea = options.safeArea ?? true;
        this.backgroundColor = options.backgroundColor ?? "";
        return this;
    }
}

export default MainOptions;