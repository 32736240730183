import {
    useColorScheme,
    useWindowDimensions,
    Platform,
    Dimensions
} from 'react-native';

import { ScreenSize } from '../../specific/utils/ScreenSize';


// LILI - Color
export const black70 = "#00000030";
export const dark = "#1d2835";
export const dark_grey_blue = "#1C3656";
export const dark_grey_blue_90 = "#1C3656CC";
export const dark_grey_blue_70 = "#1C365670";
export const dark_grey_blue_30 = "#1C365630";
export const greeny_blue = "#42bc99";
export const dark_green = "#22A18E";
export const melon = "#ff855f";
export const off_white = "#fff1e1";
export const pale = "#faefdf";
export const pale_sky_blue = "#bdf3f9";
export const pale_sky_blue_70 = "#bdf3f970";
export const paleViolet = '#bba9fc';
export const paleViolet50 = '#bba9fc50';
export const violet = '#AFA0F7';
export const ultraPaleViolet = '#D0CAF3';
export const purplish_brown = "#3d3337";
export const sandy = "#f3d676";
export const white = "#FFFFFF";
export const white30 = "#FFFFFF30";
export const white70 = "#FFFFFF70";
export const white90 = "#FFFFFF90";
export const clear = "#FFFFFFFF";
export const light_grey = "#EFEFEF";
export const orange_yellow = "#FFB000";
export const gold = "#F4D37F";

export const dark_purple = "#5B5778";
export const light_yellow = "#F3D676";
export const nightSky = "#1C3656";
export const daySky = "#75DFEA";
export const grassGreen = "#00BC98";
export const superPower = "#364A7B";

const lightMainColor: string = pale_sky_blue;
const darkMainColor: string = "#1C3656";

const mobileBreakpoint = 460;
const webWidth = 1024;

export class Theme {
    public primaryColor: string;
    public secondaryColor: string;
    public secondaryBackgroundColor: string;
    public navigationColor: string;
    public navigationItemColor: string;
    public titleColor: string;
    public textColor: string;
    public inputBackgroundColor: string;
    public buttonBackgroundColor: string;
    public buttonTextColor: string;
    public primaryFont: string;
    public secondaryFont: string;
    public alertOverlayColor: string;
    public alertBackgroundColor: string;
    public schoolBackgroundColor: string;
    public schoolTextColor: string;
    public schoolColor: string;
    public homeBackgroundColor: string;
    public homeTextColor: string;
    public homeColor: string;
    public typeChoicColor: string;

    public daySkyColor: string;
    public grassColor: string;

    public white: string;

    constructor() {
        this.primaryColor = "#ffffff";
        this.secondaryColor = lightMainColor;
        this.secondaryBackgroundColor = lightMainColor + "66";
        this.navigationColor = lightMainColor;
        this.navigationItemColor = lightMainColor;
        this.titleColor = "#303030";
        this.textColor = "#404040";
        this.inputBackgroundColor = "#ffffff";
        this.buttonBackgroundColor = darkMainColor;
        this.buttonTextColor = "#ffffff";
        this.primaryFont = "Dosis";
        this.secondaryFont = "Abel";
        this.alertOverlayColor = "#00000080";
        this.alertBackgroundColor = "#FFFFFF";
        this.white = "#fdfdfd";
        this.schoolBackgroundColor = off_white;
        this.schoolTextColor = "#1C3656";
        this.schoolColor = dark_purple;
        this.homeBackgroundColor = "#1C3656";
        this.homeTextColor = white;
        this.homeColor = light_yellow;
        this.typeChoicColor = melon;
        this.daySkyColor = pale_sky_blue;
        this.grassColor = grassGreen;
        return this;
    }
}

class AppTheme extends Theme {
    //isDarkMode: boolean = useColorScheme() === 'light';

    isDarkMode: boolean = false;
    windowWidth: number = useWindowDimensions().width;
    windowHeight: number = useWindowDimensions().height;

    screenSize: ScreenSize = new ScreenSize();

    constructor() {
        super();
        this.primaryColor = this.isDarkMode === true ? darkMainColor : lightMainColor;
        this.secondaryColor = this.isDarkMode === true ? lightMainColor : darkMainColor;
        this.navigationItemColor = this.isDarkMode === true ? "#040404" : "#ffffff";
        this.titleColor = this.isDarkMode === true ? "#ffffff" : "#303030";
        this.textColor = this.isDarkMode === true ? "#ffffff" : "#404040";
        this.buttonBackgroundColor = this.isDarkMode === true ? darkMainColor : darkMainColor;
        this.buttonTextColor = this.isDarkMode === true ? lightMainColor : darkMainColor;

        return this;
    }

    public getFullAppWidth() {
        let webFullWidthScreen: number = Dimensions.get("window").width > webWidth ? webWidth : Dimensions.get("window").width;
        //webFullWidthScreen = Dimensions.get("window").width;
        return Platform.OS === "web" ? webFullWidthScreen : Dimensions.get("window").width;
    }

    public getFullAppHeight() {
        return Dimensions.get("window").height - this.screenSize.safeAreaBottomHeight - this.screenSize.safeAreaTopHeight;
    }

    public getFullScreenWidth() {
        return Dimensions.get("window").width;
    }

    public getFullScreenHeight() {
        return Dimensions.get("window").height;
    }

    public getFlexDirectionForSplitScreen() {
        let flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse' | undefined = "column";
        const { width, height } = Dimensions.get("window");
        if (width > height) {
            // Mode portrait
            flexDirection = "row";
        }
        return flexDirection;
    }
}

export default AppTheme;