import React, { useState } from "react";

import {
    Dimensions,
    StyleSheet,
    Pressable,
    Text,
    ActivityIndicator,
    View
} from 'react-native';

import { pixelPerfect, pixelPerfectForFont, pixelPerfectForLineHeight } from "../utils/PixelPerfect";
import AppTheme, { Theme } from "../utils/Theme";

import SVGView from "../utils/SvgView";
import { default as Check } from "../assets/svg/icons/solid/check.svg";

export const horizontalMargin = pixelPerfect(20);

const RadioButton = (props: any) => {

    const {
        disabled,
        onLongPress,
        onPress,
        style,
        radioButtonStyle,
        value=false
    } = props;

    const [isSelected, setIsSelected] = useState(value);

    const appTheme: AppTheme = new AppTheme();

    const buttonStyle = styles.pressable;
    let selectedBackgroundColor = ((style !== undefined) && (style.backgroundColor !== undefined)) ? style.backgroundColor : appTheme.buttonBackgroundColor;
    let unSelectedBackgroundColor = appTheme.white;
    const isDisabled = disabled !== undefined ? disabled : false;

    const onRadioButtonPress = () => {
        if (onPress !== undefined) {
            onPress({value:!isSelected});
        }
        setIsSelected(!isSelected);
    }

    return (
        <Pressable
            disabled={isDisabled}
            onLongPress={onLongPress}
            onPress={onRadioButtonPress}
            style={({ pressed }) => [
                {
                    opacity: pressed === true ? 0.5 : 1,
                    flexDirection:"row"
                },
                style]}>
            <View style={[buttonStyle, radioButtonStyle, { backgroundColor: isSelected === true ? selectedBackgroundColor : unSelectedBackgroundColor, borderColor: selectedBackgroundColor }]}>
                <SVGView Component={Check} width={10} height={10} color={appTheme.white} />
            </View>
            {props.children}
        </Pressable>
    )

}

export default RadioButton;

const styles = StyleSheet.create({
    pressable: {
        width: pixelPerfect(13),
        height: pixelPerfect(13),
        borderRadius: pixelPerfect(8),
        justifyContent: "center",
        alignItems: "center",
        borderWidth: pixelPerfect(0.5), 
        marginEnd: pixelPerfect(4)
    }
});