import React from "react";
import { useState, useEffect } from "react";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image
} from 'react-native';

import AppTheme from "../../../utils/Theme";
import * as Constants from "../../../utils/Constants";
import MainOptions from "../../../models/MainOptions";
import { pixelPerfect, pixelPerfectForFont } from "../../../utils/PixelPerfect";

// Assets
import Images from "../../../../specific/utils/Images";

// SVG
import i18n from "../../../utils/Localization/Localization";

interface Props {
    name: string;
}

const OnboardingTeacherPageThree = (props: any) => {

    const [isLoading, setIsLoading] = useState(true);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const ressourcesList = [
        {
            image: images.icClassMaterial,
            title: i18n.t("onboarding.teacher.pageThree.classMaterial")
        },
        {
            image: images.icSchoolMaterial,
            title: i18n.t("onboarding.teacher.pageThree.schoolMaterial")
        },
        {
            image: images.icLinkSheets,
            title: i18n.t("onboarding.teacher.pageThree.linkSheets")
        },
        {
            image: images.icFormationModules,
            title: i18n.t("onboarding.teacher.pageThree.formationModules")
        },
        {
            image: images.icTeacherFacebook,
            title: i18n.t("onboarding.teacher.pageThree.teacherFacebook.title"),
            subtitle: i18n.t("onboarding.teacher.pageThree.teacherFacebook.subtitle")
        }
    ]

    // ComponentDidMount
    useEffect(() => {

    }, []);

    const getRessourcesView = () => {
        let ressourcesListView = [];
        for (const aRessourcesListIndex in ressourcesList) {
            if (Object.prototype.hasOwnProperty.call(ressourcesList, aRessourcesListIndex)) {
                const aRessource = ressourcesList[aRessourcesListIndex];
                let subtitle = <View />;
                if (aRessource.subtitle !== undefined) {
                    subtitle = <Text style={[styles.subtitle, { textAlign:"left", width:pixelPerfect(200), paddingStart: pixelPerfect(20), fontFamily: appTheme.primaryFont, color: appTheme.white }]}>{aRessource.subtitle}</Text>
                }
                ressourcesListView.push(
                    <View style={{ flexDirection: "row", width: pixelPerfect(250), justifyContent: "flex-start", alignItems: "center", padding: pixelPerfect(10) }}>
                        <Image source={aRessource.image} style={{ width: pixelPerfect(50), height: pixelPerfect(50) }} resizeMode="contain" />
                        <View style={{ alignItems:"flex-start" }}>
                            <Text style={[styles.title, { paddingStart: pixelPerfect(20), fontFamily: appTheme.primaryFont, marginTop: pixelPerfect(10), color: appTheme.white }]}>{aRessource.title.toLocaleUpperCase()}</Text>
                            {subtitle}
                        </View>
                    </View>
                )
            }
        }
        return <View style={{ backgroundColor: appTheme.schoolColor, paddingVertical: pixelPerfect(10), marginTop: pixelPerfect(20), borderRadius: pixelPerfect(20) }}>
            {ressourcesListView}
        </View>
    }

    return (
        <View style={[styles.main, { width: appTheme.getFullAppWidth(), paddingHorizontal: pixelPerfect(10), paddingTop: pixelPerfect(10) }]}>
            <Image source={images.icSchoolBag} style={{ width: pixelPerfect(60), height: pixelPerfect(50) }} resizeMode="contain" />
            <Text style={[styles.title, { fontFamily: appTheme.primaryFont, marginTop: pixelPerfect(20), color: appTheme.schoolColor }]}>{i18n.t("onboarding.teacher.pageThree.title").toLocaleUpperCase()}</Text>
            {getRessourcesView()}
        </View>
    )
}

export default OnboardingTeacherPageThree;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        textAlign: "center",
        fontSize: pixelPerfectForFont(10),
        fontWeight: "500",
        letterSpacing: 1
    },
    subtitle: {
        textAlign: "center",
        fontSize: pixelPerfectForFont(10),
        fontWeight: "500",
        letterSpacing: 1
    },
    legend: {
        textAlign: "center",
        fontSize: pixelPerfectForFont(10),
        letterSpacing: 1
    }
});