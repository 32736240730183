export function push(data: { componentId?: string, history?: any, passProps?: any, name: string, options?: any }) {
    const { componentId = "", history = {}, passProps = {}, name, options = {} } = data;
    history.push(name, passProps);
}

export function pop(data: { componentId?: string, history?: any}) {
    const { componentId = "", history = {} } = data;
    history.goBack();
}

export function showModal(data: { componentId?: string, history?: any, passProps?: any, name: string, options?: any }) {
    const { componentId = "", history = {}, passProps = {}, name, options = {} } = data;
    history.push(name, passProps);
}

export function dismissModal(data: { componentId?: string, history?: any}) {
    const { componentId = "", history = {} } = data;
    history.goBack();
}