import * as Auth from "../../specific/services/Specific_Auth";

export function isConnnected() {
    const user = currentUser();
    if (user === null) {
        return false
    }
    return true;
}

export function currentUser() {
    return Auth.dbCurrentUser();
}

export async function createUserWithEmailAndPassword(credential: { email:string, password:string }) {
    try {
        const authResult = await Auth.dbCreateUserWithEmailAndPassword({
            email: credential.email, 
            password: credential.password
        });
        if (authResult !== undefined) {
            return authResult;
        }
        return undefined;
    } catch (error) {
        throw error;
        
    }
}

export function signInWithEmailAndPassword(credential: { email:string, password:string }) {
    return new Promise(function (resolve, reject) {
        Auth.dbSignInWithEmailAndPassword({email:credential.email, password:credential.password}).then((result) => {
            if (result.user !== undefined) {
                resolve(result.user);
            } else {
                resolve(undefined);
            }
        }).catch(error => {
            reject(error);
        });
    });
}

export function signOut() {
    Auth.dbSignOut();
}